import { InfoCircledIcon } from '@radix-ui/react-icons'
import { createColumnHelper } from '@tanstack/react-table'
import { convertTimestampToIverifyDate } from '../../utils/dates'
import { Activity, ScanError } from '../../types/ActivityTypes'
import { generateMethodView } from '../alert-console/AlertColumns'
import { Badge, Button, Tooltip } from '@radix-ui/themes'
import { ScanMethod } from '../../types/AlertTypes'
import { useState } from 'react'
import LoadingIcon from '../../assets/loading.svg'
import CloseIcon from '../../assets/close-circle.svg'
import { IconExclamationCircleFilled, IconFileDownload } from '@tabler/icons-react'
import Scroll from '../scroll/Scroll'

export const activityColumns = (
  getScanDetails: (scanId: string, scanType: string) => void,
  downloadAlertReport: (scanId: string, isThreatHunter: boolean) => Promise<void>,
  methodToShow: string
) => {
  const columnHelper = createColumnHelper<Activity>()

  const columns = [
    columnHelper.accessor('memberName', {
      header: 'Device Name',
      cell: (row) => {
        if (row.row.original.scanType === ScanMethod.THREAT_HUNTER) {
          return (
            <Tooltip
              content="Unique device identifier associated with the scan"
              side="top"
              align="end"
              className="max-w-[240px]"
            >
              <Scroll classNames="my-2 pb-1">
                <div className="flex">
                  <InfoCircledIcon className="h-4 w-4 mr-1 mt-0.5" />
                  <p>{row.row.original.memberName}</p>
                </div>
              </Scroll>
            </Tooltip>
          )
        } else {
          return <Scroll classNames="my-2 pb-1">{row.row.original.memberName || 'Unknown'}</Scroll>
        }
      },
    }),
    columnHelper.accessor('model', {
      header: 'Device',
      cell: (row) => {
        return row.row.original.model !== 'null' ? row.row.original.model : 'Unknown'
      },
    }),
    columnHelper.accessor('accessCode', {
      header: 'Scanned By Code',
      cell: (row) => {
        if (row.row.original.scanType === ScanMethod.THREAT_HUNTER) {
          return (
            <Tooltip
              content="Access code of the Threat Hunter user that initiated the scan"
              side="top"
              align="end"
              className="max-w-[240px]"
            >
              <div className="flex">
                <InfoCircledIcon className="h-4 w-4 mr-1 mt-0.5" />
                <p>{row.row.original.accessCode}</p>
              </div>
            </Tooltip>
          )
        } else {
          return row.row.original.accessCode || 'Unknown'
        }
      },
    }),
    columnHelper.accessor('scanType', {
      header: 'Scan Method',
      cell: (row) => {
        return row.row.original.scanError ? (
          <div className="flex gap-1">
            <IconExclamationCircleFilled className="text-neutral-9" />
            {generateError(row.row.original.scanError!)}
          </div>
        ) : (
          generateMethodView(row.row.original.scanType)
        )
      },
    }),
    columnHelper.accessor('createdAt', {
      cell: (info) => convertTimestampToIverifyDate(info.getValue(), true),
      header: 'Scan Time',
    }),
    columnHelper.accessor('alertCreated', {
      cell: (info) => generateAlertPill(info.getValue()),
      header: 'Alert Created',
    }),
    {
      id: 'button',
      cell: (row: { row: { original: Activity } }) => (
        <Button
          size={'2'}
          className="cursor-pointer float-right"
          onClick={() => {
            getScanDetails(row.row.original.scanId, row.row.original.scanType)
          }}
          variant="outline"
        >
          View
        </Button>
      ),
    },
  ]

  if (methodToShow !== 'edr') {
    columns.splice(columns.length - 1, 0, {
      id: 'button',
      header: 'Report',
      cell: (row: { row: { original: Activity } }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [loading, setLoading] = useState(false)

        const handleClick = () => {
          setLoading(true)
          const isThreatHunter = row.row.original.scanType === ScanMethod.THREAT_HUNTER
          downloadAlertReport(row.row.original.scanId, isThreatHunter).finally(() => setLoading(false))
        }

        return (
          <>
            {(row.row.original.scanType === ScanMethod.IVY_APP ||
              row.row.original.scanType === ScanMethod.APP_BUG_REPORT) && (
              <Tooltip
                content="Reports are only available from Threat Hunter, Elite, and iOS Forensic scans"
                side="top"
                align="end"
                className="max-w-[240px]"
              >
                <div className="h-10 w-10 flex justify-center items-center">
                  <img src={CloseIcon} alt="Download" className="h-8 w-8" />
                </div>
              </Tooltip>
            )}
            {row.row.original.scanType !== ScanMethod.IVY_APP &&
              row.row.original.scanType !== ScanMethod.APP_BUG_REPORT && (
                <>
                  {loading ? (
                    <div className="h-10 w-10 flex justify-center items-center">
                      <img src={LoadingIcon} alt="Download" className="h-8 w-8 text-PurpleBlue-11 animate-spinSlow" />
                    </div>
                  ) : (
                    <div className="h-10 w-10 flex justify-center items-center hover:bg-DownloadButtonPurple rounded cursor-pointer">
                      <IconFileDownload stroke={1.5} className="h-8 w-8 text-PurpleBlue-11" onClick={handleClick} />
                    </div>
                  )}
                </>
              )}
          </>
        )
      },
    })
  }
  return columns
}

export const generateAlertPill = (alertCreated: boolean) => {
  switch (alertCreated) {
    case true:
      return (
        <div className="flex">
          <Badge color="crimson">Yes</Badge>
        </div>
      )
    case false:
      return (
        <div className="flex">
          <Badge color="green">No</Badge>
        </div>
      )
  }
}

const generateError = (error: ScanError) => {
  switch (error) {
    case ScanError.UNSUPPORTED_FILE_TYPE:
      return 'File type not supported'
    case ScanError.FILE_PROCESSING_ERROR:
      return 'Issue processing file'
    case ScanError.INVALID_STORAGE_PATH:
      return 'Invalid storage location'
    default:
      return 'Unexpected error'
  }
}
