import { useSelector } from 'react-redux'
import { selectUser } from '../../reducers/users'
import { Button } from '@radix-ui/themes'
import { useState } from 'react'
import AddSSOMembersModal from '../members/AddSSOMembersModal'
import DisconnectModal from './DisconnectModal'
import ContentSkeleton from '../skeleton/ContentSkeleton'

function SsoSettings() {
  const { session } = useSelector(selectUser)
  const [showDisconnectModal, setShowDisconnectModal] = useState<boolean>(false)
  const [showSSOModal, setShowSSOModal] = useState<boolean>(false)

  const hasSso = () => {
    return (
      <>
        <div className="italic">
          {session!.organization.ssoProvider.charAt(0).toUpperCase() + session!.organization.ssoProvider.slice(1)}{' '}
          configured
        </div>
        <Button className="bg-ErrorRed mt-4" onClick={() => setShowDisconnectModal(true)}>
          Disconnect
        </Button>
      </>
    )
  }

  const ssoNotConfiguredBody = (
    <>
      <div className="italic">Not yet configured</div>
      <Button
        className="mt-4 bg-PurpleBlue-10 cursor-pointer"
        onClick={() => {
          setShowSSOModal(true)
        }}
      >
        Set Up SSO
      </Button>
    </>
  )

  const ssoTabBody = session?.organization.ssoProvider ? hasSso() : ssoNotConfiguredBody

  return (
    <>
      <div className="w-full h-full">
        {!session && <ContentSkeleton />}
        {session && ssoTabBody}
      </div>
      {showDisconnectModal && <DisconnectModal setShowModal={setShowDisconnectModal} screen={'SSO'} />}
      {showSSOModal && <AddSSOMembersModal setShowModal={setShowSSOModal} goToGroups={false} />}
    </>
  )
}

export default SsoSettings
