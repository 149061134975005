import { Cross2Icon, DesktopIcon, MobileIcon, ReaderIcon } from '@radix-ui/react-icons'
import AlertIcon from '../../icons/AlertIcon'
import { Button } from '@radix-ui/themes'
import { convertTimestampToIverifyDate } from '../../utils/dates'
import { Alert, ScanMethod, Severity } from '../../types/AlertTypes'
import { Threat } from '../../types/ActivityTypes'

interface IAlertPopoutProps {
  popoutData: Alert
  setShowPopout: (show: boolean) => void
  setShowResolveModal: (show: boolean) => void
}

export const AlertPopoutContent = (props: IAlertPopoutProps) => {
  const { popoutData, setShowPopout, setShowResolveModal } = props

  const handleScanMethod = (scanMethod: string) => {
    switch (scanMethod) {
      case ScanMethod.IVY_APP:
        return (
          <div className="flex">
            <MobileIcon className="mt-0.5 mr-2" />
            <p>EDR App</p>
          </div>
        )
      case ScanMethod.THREAT_HUNTER:
        return (
          <div className="flex">
            <DesktopIcon className="mt-0.5 mr-2" />
            <p>Threat Hunter</p>
          </div>
        )
      case ScanMethod.APP_SYSDIAGNOSE:
        return (
          <div className="flex">
            <ReaderIcon className="mt-0.5 mr-2" />
            <p>Sysdiagnose</p>
          </div>
        )
      case ScanMethod.APP_BUG_REPORT:
        return (
          <div className="flex">
            <ReaderIcon className="mt-0.5 mr-2" />
            <p>Bug Report</p>
          </div>
        )
      case ScanMethod.IVERIFY_ELITE:
        return (
          <div className="flex">
            <MobileIcon className="mt-0.5 mr-2" />
            <p>Elite Scan</p>
          </div>
        )
      default:
        return null
    }
  }

  const generateHeaderColour = (severity: string) => {
    switch (severity) {
      case Severity.CRITICAL:
        return 'text-[#cb1d63] bg-[#f0004715]'
      case Severity.HIGH:
        return 'bg-[#ff7f000f]'
      case Severity.SUSPICIOUS:
        return 'text-[#d14e00] bg-[#f9900026]'
      case Severity.LOW:
        return 'bg-[#ff10100f]'
      default:
        return 'text-[#cb1d63] bg-[#f0004715]'
    }
  }

  const generateHeaderIconFill = (severity: string) => {
    switch (severity) {
      case Severity.CRITICAL:
        return '#cb1d63'
      case Severity.HIGH:
        return 'bg-[#ff7f000f]'
      case Severity.SUSPICIOUS:
        return '#d14e00'
      case Severity.LOW:
        return 'bg-[#ff10100f]'
      default:
        return '#cb1d63'
    }
  }

  const checkIfUpdated = (updatedAt: string, createdAt: string) => {
    return updatedAt ? convertTimestampToIverifyDate(updatedAt, true) : convertTimestampToIverifyDate(createdAt, true)
  }

  return (
    <>
      <div
        className={`w-full h-[52px] flex justify-center items-center border-b ${generateHeaderColour(popoutData.severity)}`}
      >
        <AlertIcon iconFill={generateHeaderIconFill(popoutData.severity)} />
        <p className="text-xs font-semibold">This alert is rated as {popoutData.severity.toLowerCase()}</p>
      </div>
      {/* Name */}
      <div className="w-full">
        <div className="w-full min-h-20 border-b flex gap-2 px-6">
          <div className="flex grow flex-col justify-center">
            <div className="text-black text-lg font-semibold leading-normal">
              {popoutData.memberName}
            </div>
            <div className="text-black text-opacity-50 text-sm font-semibold leading-normal">{popoutData.device}</div>
          </div>
          <div className="h-20 flex justify-end items-center">
            <div
              className="w-6 h-6 cursor-pointer"
              onClick={() => {
                setShowPopout(false)
              }}
            >
              <Cross2Icon className="w-6 h-6" />
            </div>
          </div>
        </div>
        {/* Alert Name */}
        <div className="w-full border-b pb-4 px-8">
          <div className="flex">
            <div className="w-1/2 h-12 flex flex-col justify-center">
              <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Alert Details</div>
            </div>
          </div>
          <div className="w-full mt-2">
            {JSON.parse(popoutData.alertDescription).map((alert: Threat) => {
              return <p className="text-sm">• {alert.threat}</p>
            })}
          </div>
        </div>
        {/* Alert Created */}
        <div className="w-full h-12 border-b flex px-8">
          <div className="w-1/2 h-12 flex flex-col justify-center">
            <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">First Seen</div>
          </div>
          <div className="w-1/2 h-12 flex justify-end items-center float-right">
            <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
              {convertTimestampToIverifyDate(popoutData.createdAt, true)}
            </div>
          </div>
        </div>
        {/* Alert Updated */}
        <div className="w-full h-12 border-b flex px-8">
          <div className="w-1/2 h-12 flex flex-col justify-center">
            <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Last Seen</div>
          </div>
          <div className="w-1/2 h-12 flex justify-end items-center float-right">
            <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
              {checkIfUpdated(popoutData.updatedAt, popoutData.createdAt)}
            </div>
          </div>
        </div>
        {/* Status */}
        <div className="w-full h-12 border-b flex px-8">
          <div className="w-1/2 h-12 flex flex-col justify-center">
            <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Alert Status</div>
          </div>
          <div className="w-1/2 h-12 flex justify-end items-center float-right">
            <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
              {popoutData.alertStatus}
            </div>
          </div>
        </div>
        {/* Scanned by */}
        <div className="w-full h-12 border-b flex px-8">
          <div className="w-1/2 h-12 flex flex-col justify-center">
            <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Access Code</div>
          </div>
          <div className="w-1/2 h-12 flex justify-end items-center float-right">
            <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
              {popoutData.scannedByAccessCode}
            </div>
          </div>
        </div>
        {/* Scan method */}
        <div className="w-full h-12 border-b flex px-8">
          <div className="w-1/2 h-12 flex flex-col justify-center">
            <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Scan Type</div>
          </div>
          <div className="w-1/2 h-12 flex justify-end items-center float-right">
            <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
              {handleScanMethod(popoutData.scanMethod)}
            </div>
          </div>
        </div>
        {/* Platform */}
        <div className="w-full h-12 border-b flex px-8">
          <div className="w-1/2 h-12 flex flex-col justify-center">
            <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Platform</div>
          </div>
          <div className="w-1/2 h-12 flex justify-end items-center float-right">
            <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
              {popoutData.platform}
            </div>
          </div>
        </div>
        {/* OS */}
        <div className="w-full h-12 border-b flex px-8">
          <div className="w-1/2 h-12 flex flex-col justify-center">
            <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">OS</div>
          </div>
          <div className="w-1/2 h-12 flex justify-end items-center float-right">
            <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
              {popoutData.osVersion}
            </div>
          </div>
        </div>
        {/* Scanned By */}
        {popoutData.scanMethod === ScanMethod.THREAT_HUNTER && (
          <div className="w-full h-12 border-b flex px-8">
            <div className="w-1/2 h-12 flex flex-col justify-center">
              <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Scanned By</div>
            </div>
            <div className="w-1/2 h-12 flex justify-end items-center float-right">
              <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
                {popoutData.scannedBy}
              </div>
            </div>
          </div>
        )}
        <div className="h-20 flex items-center mt-4">
          {popoutData.alertStatus === 'OPEN' && (
            <Button
              className="w-[125px] h-10 bg-PurpleBlue-10 my-5 mr-8 ml-auto cursor-pointer"
              onClick={() => setShowResolveModal(true)}
            >
              Resolve
            </Button>
          )}
        </div>
      </div>
    </>
  )
}
