import { Button } from '@radix-ui/themes'
import { IconUpload } from '@tabler/icons-react'
import { useDropzone } from 'react-dropzone'

interface IDropZoneProps {
  setUploadedFiles: (files: File[]) => void
  customMessage: string
}

const DropZone = (props: IDropZoneProps) => {
  const { setUploadedFiles, customMessage } = props
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { 'text/csv': [] },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setUploadedFiles(acceptedFiles.map((file) => file))
    },
  })
  const borderColor = isDragActive ? 'border-purple-500' : 'border-DarkNavy/10'
  return (
    <>
      <div {...getRootProps()} className={`w-full h-auto rounded-xl mt-8 mx-auto border-2 ${borderColor} border`}>
        <input {...getInputProps()} />
        <div className="w-full h-auto flex flex-col justify-center items-center">
          {!isDragActive ? (
            <>
              <IconUpload size={32} className="mt-8" />
              <p className="mt-4 text-neutral-12">Drop your CSV here to upload</p>
              <p className="mt-1 text-neutral-11 text-xs">{customMessage}</p>
              <Button
                className="w-[92px] h-8 bg-neutral-12 rounded-lg mt-4 mb-8 p-2.5 text-white font-semibold text-sm cursor-pointer"
                value="Select file"
              >
                Select file
              </Button>
            </>
          ) : (
            <div className="flex items-center h-[204px]">
              <p className="font-semibold text-PurpleBlue-10">Drop the File Here</p>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default DropZone
