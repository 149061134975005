import { Cross2Icon, DesktopIcon, MobileIcon, ReaderIcon } from '@radix-ui/react-icons'
import AlertIcon from '../../icons/AlertIcon'
import { convertTimestampToIverifyDate } from '../../utils/dates'
import { ScanMethod } from '../../types/AlertTypes'
import { ActivityPopoutRequest, DeepScanDetails, Threat } from '../../types/ActivityTypes'
import checkIcon from '../../assets/check_circle.svg'
import { Button } from '@radix-ui/themes'
import { generateAndDownloadReport } from '../../reducers/alerts'
import { useAppDispatch } from '../../reducers/store'
import { useEffect, useState } from 'react'
import { fetchDeepScanDetails, fetchSysdiagnoseScanDetails } from '../../reducers/activity'
import { getThreatHunterUsers } from '../../reducers/settings'
import SideBarSkeleton from '../skeleton/SideBarSkeleton'

interface IDeepPopoutContentProps {
  popoutData: ActivityPopoutRequest
  setShowPopout: (show: boolean) => void
}

export const DeepPopoutContent = (props: IDeepPopoutContentProps) => {
  const di = useAppDispatch()
  const { popoutData, setShowPopout } = props
  const [downloadingReport, setDownloadingReport] = useState(false)
  const [fetchedDeepScanDetails, setFetchedEdrDeepDetails] = useState<DeepScanDetails | DeepScanDetails | undefined>(
    undefined
  )

  useEffect(() => {
    fetchScanDetails(popoutData.scanId)
  }, [])

  const fetchScanDetails = async (scanId: string) => {
    if (popoutData.scanType === ScanMethod.APP_SYSDIAGNOSE) {
      const fetchedDetails = await di(fetchSysdiagnoseScanDetails(scanId))
      setFetchedEdrDeepDetails(fetchedDetails)
    } else if (popoutData.scanType === ScanMethod.IVERIFY_ELITE) {
      const fetchedDetails = await di(fetchSysdiagnoseScanDetails(scanId))
      setFetchedEdrDeepDetails(fetchedDetails)
    } else {
      const thUsers = await di(getThreatHunterUsers())
      const fetchedDetails = await di(fetchDeepScanDetails(scanId, thUsers))
      setFetchedEdrDeepDetails(fetchedDetails)
    }
  }

  const handleScanMethod = (scanMethod: string) => {
    switch (scanMethod) {
      case ScanMethod.IVY_APP:
        return (
          <div className="flex">
            <MobileIcon className="mt-0.5 mr-2" />
            <p>EDR App</p>
          </div>
        )
      case ScanMethod.THREAT_HUNTER:
        return (
          <div className="flex">
            <DesktopIcon className="mt-0.5 mr-2" />
            <p>Threat Hunter</p>
          </div>
        )
      case ScanMethod.APP_SYSDIAGNOSE:
        return (
          <div className="flex">
            <ReaderIcon className="mt-0.5 mr-2" />
            <p>Sysdiagnose</p>
          </div>
        )
      case ScanMethod.APP_BUG_REPORT:
        return (
          <div className="flex">
            <ReaderIcon className="mt-0.5 mr-2" />
            <p>Bug Report</p>
          </div>
        )
      case ScanMethod.IVERIFY_ELITE:
        return (
          <div className="flex">
            <MobileIcon className="mt-0.5 mr-2" />
            <p>Elite Scan</p>
          </div>
        )
      default:
        return null
    }
  }

  const parseDetailsJson = (details: string) => {
    try {
      return JSON.parse(details)
    } catch (e) {
      return null
    }
  }

  const generateDetailsView = (details: string) => {
    const parsedDetails = parseDetailsJson(details)
    if (!parsedDetails || parsedDetails.length === 0) {
      return (
        <div className="block items-center mt-0">
          <div className="flex items-center justify-center">
            <img src={checkIcon} alt="No open alerts found" className="h-12 w-12" />
          </div>{' '}
          <p className="text-center font-bold">No Alert</p>
          <p className="text-center text-sm">You have no actions for now</p>
        </div>
      )
    }
    return (
      <div className="w-full mt-2">
        {parsedDetails.map((check: Threat) => {
          return <p className="text-sm">• {check.threat}</p>
        })}
      </div>
    )
  }

  const downloadReport = async (scanId: string, scanType: string) => {
    setDownloadingReport(true)
    const isThreatHunter = scanType === ScanMethod.THREAT_HUNTER
    await di(generateAndDownloadReport(scanId, isThreatHunter))
      .then(() => {
        setDownloadingReport(false)
      })
      .catch(() => {
        setDownloadingReport(false)
      })
  }

  return (
    <>
      {!fetchedDeepScanDetails && <SideBarSkeleton />}
      {fetchedDeepScanDetails && (
        <>
          {fetchedDeepScanDetails.alertCreated && (
            <div className={`w-full h-[52px] flex justify-center items-center border-b text-[#cb1d63] bg-[#f0004715]`}>
              <AlertIcon iconFill={'#cb1d63'} />
              <p className="text-xs font-semibold">This scan created an alert</p>
            </div>
          )}

          {/* Name */}
          <div className="w-full">
            <div className="w-full h-20 border-b flex px-8">
              <div className="w-3/4 h-20 flex flex-col justify-center">
                <div className="text-black text-lg font-semibold leading-normal whitespace-normal max-two-lines">
                  {fetchedDeepScanDetails.memberName}
                </div>
                <div className="text-black text-opacity-50 text-sm font-semibold leading-normal">
                  {fetchedDeepScanDetails.model}
                </div>
              </div>
              <div className="w-1/4 h-20 flex justify-end items-center float-right">
                <div
                  className="w-6 h-6 cursor-pointer"
                  onClick={() => {
                    setShowPopout(false)
                  }}
                >
                  <Cross2Icon className="w-6 h-6" />
                </div>
              </div>
            </div>
            {/* Alert Name */}
            <div className="w-full h-[auto] border-b px-8 pb-4">
              <div className="flex">
                <div className="w-1/2 h-12 flex flex-col justify-center">
                  <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Alert Details</div>
                </div>
              </div>
              {generateDetailsView(fetchedDeepScanDetails.details)}
            </div>
            {/* Scan time */}
            <div className="w-full h-12 border-b flex px-8">
              <div className="w-1/2 h-12 flex flex-col justify-center">
                <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Scan Time</div>
              </div>
              <div className="w-1/2 h-12 flex justify-end items-center float-right">
                <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
                  {convertTimestampToIverifyDate(fetchedDeepScanDetails.createdAt, true)}
                </div>
              </div>
            </div>
            {/* Scanned by */}
            <div className="w-full h-12 border-b flex px-8">
              <div className="w-1/2 h-12 flex flex-col justify-center">
                <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Access Code</div>
              </div>
              <div className="w-1/2 h-12 flex justify-end items-center float-right">
                <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
                  {fetchedDeepScanDetails.accessCode}
                </div>
              </div>
            </div>
            {/* Scan method */}
            <div className="w-full h-12 border-b flex px-8">
              <div className="w-1/2 h-12 flex flex-col justify-center">
                <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Scan Type</div>
              </div>
              <div className="w-1/2 h-12 flex justify-end items-center float-right">
                <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
                  {handleScanMethod(fetchedDeepScanDetails.scanType)}
                </div>
              </div>
            </div>
            {/* Platform */}
            <div className="w-full h-12 border-b flex px-8">
              <div className="w-1/2 h-12 flex flex-col justify-center">
                <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Platform</div>
              </div>
              <div className="w-1/2 h-12 flex justify-end items-center float-right">
                <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
                  {fetchedDeepScanDetails.platform}
                </div>
              </div>
            </div>
            {fetchedDeepScanDetails.scanType === ScanMethod.THREAT_HUNTER && (
              <>
                {/* Scanned By */}
                <div className="w-full h-12 border-b flex px-8">
                  <div className="w-1/2 h-12 flex flex-col justify-center">
                    <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Scanned By</div>
                  </div>
                  <div className="w-1/2 h-12 flex justify-end items-center float-right">
                    <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
                      {fetchedDeepScanDetails.scannedBy}
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* OS Version */}
            <div className="w-full h-12 border-b flex px-8">
              <div className="w-1/2 h-12 flex flex-col justify-center">
                <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">OS Version</div>
              </div>
              <div className="w-1/2 h-12 flex justify-end items-center float-right">
                <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
                  {fetchedDeepScanDetails.iosVersion}
                </div>
              </div>
            </div>
            {fetchedDeepScanDetails.scanType === ScanMethod.THREAT_HUNTER && (
              <div className="h-20 flex items-center mt-4">
                <Button
                  className={`w-[160px] text-white h-10 bg-PurpleBlue-10 ${downloadingReport ? 'opacity-50' : ''} my-5 mr-8 ml-auto cursor-pointer`}
                  onClick={() => downloadReport(fetchedDeepScanDetails.scanId, fetchedDeepScanDetails.scanType)}
                  disabled={downloadingReport}
                >
                  {downloadingReport ? 'Downloading...' : 'Download Report'}
                </Button>
              </div>
            )}
            {fetchedDeepScanDetails.alertCreated && <div className="flex items-center mt-4" />}
          </div>
        </>
      )}
    </>
  )
}
