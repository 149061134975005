import {
  setAdmins,
  setApiKey,
  setBillingInfo,
  setPlanInfo,
  setWebhookSecret,
  setWebhook,
  setSsoGroups,
  setSsoMembers,
  setScim,
  setMdm,
  setEmailPreference,
  setNotificationPreference,
  setEmailEnrollmentTemplate,
  setThreatHunterUsers,
  setAuditLogs,
  setAuditLogsTotalPages,
  setAuditLogsCurrentPage,
} from '.'
import {
  API_URL,
  ADMINS_ROUTE,
  RESEND_INVITE_ADMIN_ROUTE,
  ADD_ADMIN_ROUTE,
  CUSTOMER_BILLING_ROUTE,
  CUSTOMER_PLAN_ROUTE,
  SSO_ROUTE,
  SSO_GROUPS_ROUTE,
  CREATE_SSO_MEMBERS_ROUTE,
  API_ROUTE,
  WEBHOOK_ROUTE,
  WEBHOOK_SECRET_ROUTE,
  SCIM_ROUTE,
  MDM_ROUTE,
  EMAIL_PREFERENCES_ROUTE,
  NOTIFICATION_PREFERENCES_ROUTE,
  ENROLLMENT_EMAIL_TEMPLATE_ROUTE,
  PUT_ENROLLMENT_EMAIL_TEMPLATE_ROUTE,
  THREAT_HUNTER_USERS_ROUTE,
  ADD_THREAT_HUNTER_USER_ROUTE,
  CONDITIONAL_ACCESS_CONFIG_ROUTE,
  CONDITIONAL_ACCESS_POLICY_ROUTE,
  CONDITIONAL_ACCESS_DELETE_ROUTE,
  DELETE_ADMIN_ROUTE,
  AUDIT_ROUTE,
} from '../../strings/api-consts'
import {
  AddSsoSettings,
  Admin,
  Admins,
  ApiKey,
  AuditLogFilter,
  AuditLogs,
  BillingInfo,
  ConditionalAccessPolicyResponse,
  ConditionalAccessResponse,
  CreateSSOMemberResponse,
  CreateSsoMembersPayload,
  EmailPreferences,
  EnrollEmailTemplate,
  Mdm,
  MemberGroup,
  MembersResponse,
  NotificationPreferences,
  Plan,
  Scim,
  SsoGroups,
  ThreatHunterUsersResponse,
  WebhookPayload,
  WebhookSecret,
  addAdminInputs,
} from '../../types/SettingsType'
import { CustomThunkDispatch } from '../../types/UserTypes'
import * as Sentry from '@sentry/react'

export const getAdmins = () => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + ADMINS_ROUTE

  await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((admins: Admins) => {
      dispatch(setAdmins(admins.admins))
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return false
    })
}

export const resendAdminInvite = (adminInfo: Admin) => async () => {
  const url = API_URL + RESEND_INVITE_ADMIN_ROUTE

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify(adminInfo),
  })
    .then(() => {
      return true
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return false
    })
}

export const addAdmin = (adminInfo: Admin) => async () => {
  const url = API_URL + ADD_ADMIN_ROUTE

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify(adminInfo),
  })
    .then((response) => {
      if (!response.ok) {
        return false
      }
      return true
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return false
    })
}

export const deleteAdmin = (email: string) => async () => {
  const url = API_URL + DELETE_ADMIN_ROUTE

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify({ email }),
  })
    .then((response) => {
      if (!response.ok) {
        return false
      }
      return true
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return false
    })
}

export const fetchAuditLogs =
  (
    pageNumber: number,
    pageSize: number,
    filters: AuditLogFilter,
    sortOrder?: string,
    sortColumn?: string,
    key = null,
    value = null
  ) =>
  async (dispatch: CustomThunkDispatch) => {
    let url = `${API_URL}${AUDIT_ROUTE}?page=${pageNumber}&pageSize=${pageSize}`
    const { fromDateTime, toDateTime } = filters
    if (sortColumn) {
      url += `&sortColumn=${sortColumn}`
      if (sortOrder) {
        url += `&sortOrder=${sortOrder}`
      }
    }

    if (fromDateTime) {
      url += `&fromDateTime=${fromDateTime}`
    }

    if (toDateTime) {
      url += `&toDateTime=${toDateTime}`
    }

    if (key && value) {
      url += `&key=${key}&value=${value}`
    }

    return await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((audits: AuditLogs) => {
        dispatch(setAuditLogsCurrentPage(!pageNumber ? 0 : pageNumber - 1))
        dispatch(setAuditLogs(audits.data))
        dispatch(setAuditLogsTotalPages(audits.totalPages))
        return true
      })
      .catch((_err) => {
        return false
      })
  }

export const getBillingInfo = () => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + CUSTOMER_BILLING_ROUTE

  await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((billingInfo: BillingInfo) => {
      dispatch(setBillingInfo(billingInfo))
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return false
    })
}

export const getPlanInfo = () => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + CUSTOMER_PLAN_ROUTE

  await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((planInfo: Plan) => {
      dispatch(setPlanInfo(planInfo))
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return false
    })
}

export const applySsoSettings = (adminInfo: AddSsoSettings) => async () => {
  const url = API_URL + SSO_ROUTE

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify(adminInfo),
  })
    .then((response) => {
      if (!response.ok) {
        return false
      }
      localStorage.setItem('SSO_CONFIGURED_COOKIE', 'true')
      window.location.href = import.meta.env.VITE_API_ENDPOINT + `/oauth/login?slug=${adminInfo.slug}`
      return true
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return false
    })
}

export const deleteSsoSettings = () => async () => {
  const url = API_URL + SSO_ROUTE
  return fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
  })
    .then((response) => {
      if (!response.ok) {
        return false
      }
      return true
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return false
    })
}

export const getSsoGroups = () => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + SSO_GROUPS_ROUTE

  await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((ssoGroups: SsoGroups) => {
      dispatch(setSsoGroups(ssoGroups))
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      dispatch(setSsoGroups(undefined))
    })
}

export const getAPIKey = () => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + API_ROUTE

  await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((apiKey: ApiKey) => {
      dispatch(setApiKey(apiKey))
    })
    .catch((err) => {
      dispatch(setApiKey(undefined))
      Sentry.captureMessage(err.message)
      return false
    })
}

export const fetchSsoMembersForGroups = (payload: MemberGroup[]) => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + SSO_GROUPS_ROUTE

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((ssoMembers: MembersResponse) => {
      dispatch(setSsoMembers(ssoMembers))
      return ssoMembers
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      dispatch(setSsoMembers(undefined))
      return undefined
    })
}

export const generateAPIKey = (expiration: string) => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + API_ROUTE

  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify({ tokenExpiration: expiration }),
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((apiKey: ApiKey) => {
      dispatch(setApiKey(apiKey))
    })
    .catch((err) => {
      dispatch(setApiKey(undefined))
      Sentry.captureMessage(err.message)
      return false
    })
}

export const revokeAPIKey = () => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + API_ROUTE

  await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
  })
    .then(() => {
      dispatch(setApiKey(undefined))
    })
    .catch((err) => {
      dispatch(setApiKey(undefined))
      Sentry.captureMessage(err.message)
      return false
    })
}

export const getWebhook = () => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + WEBHOOK_ROUTE

  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((webhook: WebhookPayload) => {
      dispatch(setWebhook(webhook))
      dispatch(setWebhookSecret({ secret: webhook.secret }))
      return webhook
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return undefined
    })
}

export const createSsoMembers = (membersPayload: CreateSsoMembersPayload) => async () => {
  const url = API_URL + CREATE_SSO_MEMBERS_ROUTE
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify(membersPayload),
  })
    .then((res) => res.json())
    .then((response: CreateSSOMemberResponse) => {
      return response
    })
    .catch((err) => {
      return err
    })
}

export const getWebhookSecret = () => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + WEBHOOK_SECRET_ROUTE

  await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((apiKey: WebhookSecret) => {
      dispatch(setWebhookSecret(apiKey))
    })
    .catch((err) => {
      dispatch(setWebhookSecret(undefined))
      Sentry.captureMessage(err.message)
      return false
    })
}

export const saveWebhook = (payload: WebhookPayload) => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + WEBHOOK_ROUTE

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(payload),
    credentials: 'include',
  })
    .then((res) => {
      if (res.status === 200) {
        dispatch(setWebhook(payload))
        return { success: res.ok, message: 'Webhook has been successfully set up' }
      }
      return res.text().then((body) => {
        return { success: res.ok, message: body }
      })
    })
    .catch((err) => {
      return { success: false, message: err.message }
    })
}

export const deactivateWebhook = () => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + WEBHOOK_ROUTE

  return fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
  })
    .then(() => {
      dispatch(setWebhook(undefined))
    })
    .catch(() => {
      dispatch(setWebhook(undefined))
    })
}

export const getScimStatus = () => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + SCIM_ROUTE

  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((scim: Scim) => {
      dispatch(setScim(scim))
    })
    .catch(() => {
      dispatch(setScim({ enabled: false, scimUrl: '', authHeader: '' }))
    })
}

export const updateScimStatus = (status: boolean) => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + SCIM_ROUTE

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify({ enable: status }),
  })
    .then((res) => res.json())
    .then((scim: Scim) => {
      dispatch(setScim(scim))
    })
    .catch(() => {
      dispatch(setScim({ enabled: false, scimUrl: '', authHeader: '' }))
    })
}

export const getMdmStatus = () => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + MDM_ROUTE

  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((mdm: Mdm) => {
      dispatch(setMdm(mdm))
    })
    .catch(() => {
      dispatch(setMdm({ enabled: false, token: '' }))
    })
}

export const updateMdmStatus = (status: boolean) => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + MDM_ROUTE

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify({ enable: status }),
  })
    .then((res) => res.json())
    .then((mdm: Mdm) => {
      dispatch(setMdm(mdm))
    })
    .catch(() => {
      dispatch(setMdm({ enabled: false, token: '' }))
    })
}

export const getEmailPreferences = () => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + EMAIL_PREFERENCES_ROUTE

  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((emailPreferences: EmailPreferences) => {
      dispatch(setEmailPreference(emailPreferences))
    })
    .catch(() => {
      dispatch(setEmailPreference(undefined))
    })
}

export const getThreatHunterUsers = () => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + THREAT_HUNTER_USERS_ROUTE

  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((threatHunterUsers: ThreatHunterUsersResponse) => {
      dispatch(setThreatHunterUsers(threatHunterUsers.threatHunterUsers))
      return threatHunterUsers
    })
    .catch((error) => {
      dispatch(setThreatHunterUsers(undefined))
      return { error: error }
    })
}

export const getNotificationPreferences = () => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + NOTIFICATION_PREFERENCES_ROUTE

  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((notificationPreferences: NotificationPreferences) => {
      dispatch(setNotificationPreference(notificationPreferences))
    })
    .catch(() => {
      dispatch(setNotificationPreference(undefined))
    })
}

export const addThreatHunterUser = (user: addAdminInputs) => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + ADD_THREAT_HUNTER_USER_ROUTE

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify(user),
  })
    .then((res) => res.json())
    .then((threatHunterUsers: ThreatHunterUsersResponse) => {
      dispatch(setThreatHunterUsers(threatHunterUsers.threatHunterUsers))
      return threatHunterUsers
    })
    .catch((error) => {
      dispatch(setThreatHunterUsers(undefined))
      return { error: error }
    })
}

export const putEmailPreferences = (payload: EmailPreferences) => async () => {
  const url = API_URL + EMAIL_PREFERENCES_ROUTE

  return fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify(payload),
  })
    .then(() => {
      return true
    })
    .catch(() => {
      return false
    })
}

export const putNotificationPreferences = (payload: NotificationPreferences) => async () => {
  const url = API_URL + NOTIFICATION_PREFERENCES_ROUTE

  return fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify(payload),
  })
    .then(() => {
      return true
    })
    .catch(() => {
      return false
    })
}

export const getEnrollmentEmailTemplate = () => async (dispatch: CustomThunkDispatch) => {
  const url = API_URL + ENROLLMENT_EMAIL_TEMPLATE_ROUTE

  await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((enrollEmailTemplate: EnrollEmailTemplate) => {
      dispatch(setEmailEnrollmentTemplate(enrollEmailTemplate))
    })
    .catch(() => {
      dispatch(setEmailEnrollmentTemplate(undefined))
    })
}

export const putEnrollmentEmailTemplate = (payload: EnrollEmailTemplate) => async () => {
  const url = API_URL + PUT_ENROLLMENT_EMAIL_TEMPLATE_ROUTE

  return fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify(payload),
  })
    .then(() => {
      return true
    })
    .catch(() => {
      return false
    })
}

export const getConditionalAccessConfig = () => async () => {
  const url = API_URL + CONDITIONAL_ACCESS_CONFIG_ROUTE

  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((conditionalAccess: ConditionalAccessResponse) => {
      return conditionalAccess
    })
    .catch(() => {
      return undefined
    })
}

export const postConditionalAccessConfig = (payload: ConditionalAccessResponse) => async () => {
  const url = API_URL + CONDITIONAL_ACCESS_CONFIG_ROUTE

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify(payload),
  })
    .then((res) => {
      if (res.status === 202) {
        return { error: undefined }
      }
      return res.json().then((data) => {
        if (!res.ok) {
          return { error: data.error || 'Unknown error' }
        } else {
          return { error: undefined }
        }
      })
    })
    .catch((error) => {
      return { error: error.message }
    })
}

export const getConditionalAccessPolicy = () => async () => {
  const url = API_URL + CONDITIONAL_ACCESS_POLICY_ROUTE

  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((conditionalAccess: ConditionalAccessPolicyResponse) => {
      return conditionalAccess
    })
    .catch(() => {
      return undefined
    })
}

export const putConditionalAccessPolicy = (payload: ConditionalAccessPolicyResponse) => async () => {
  const url = API_URL + CONDITIONAL_ACCESS_POLICY_ROUTE
  const updatedPayload = {
    ...payload,
    screenLockEnabled: payload.screenLockEnabled === 'ENABLED' ? true : false,
    smishingEnabled: payload.smishingEnabled === 'ENABLED' ? true : false,
    biometricsEnabled: payload.biometricsEnabled === 'ENABLED' ? true : false,
  }

  return fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify(updatedPayload),
  })
    .then(() => {
      return true
    })
    .catch(() => {
      return false
    })
}

export const deleteConditionalAccess = () => async () => {
  const url = API_URL + CONDITIONAL_ACCESS_DELETE_ROUTE

  return fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
  })
    .then(() => {
      return true
    })
    .catch(() => {
      return false
    })
}

export const postDisableConditionalAccess = (payload: ConditionalAccessResponse) => async () => {
  const url = API_URL + CONDITIONAL_ACCESS_CONFIG_ROUTE
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify(payload),
  })
    .then(() => {
      return true
    })
    .catch(() => {
      return false
    })
}
