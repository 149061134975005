import toast from 'react-hot-toast'
import { ALERTS_ROUTE, API_URL, ALERTS_CLOSE_ROUTE, ALERT_REPORT_ROUTE } from '../../strings/api-consts'
import { AlertResponse, ScanMethod } from '../../types/AlertTypes'
import { SelectOptions } from '../../types/DropDownTypes'
import { setAlerts, setCurrentPage, setTotalPages } from './alerts.slice'
import { ThreatHunterUsersResponse } from '../../types/SettingsType'
import { alertOptions } from '../../strings/dropdown-options'
import { CustomThunkDispatch } from '../../types/UserTypes'
import * as Sentry from '@sentry/react'

export const fetchAlerts =
  (
    pageNumber: number,
    pageSize: number,
    statusFilter: SelectOptions[],
    threatHunterUsers: ThreatHunterUsersResponse | { error: string },
    sortOrder?: string,
    sortColumn?: string
  ) =>
  async (dispatch: CustomThunkDispatch) => {
    let url = `${API_URL + ALERTS_ROUTE}?page=${pageNumber}&pageSize=${pageSize}`

    if (statusFilter.length > 0 && statusFilter.length !== alertOptions.length) {
      const statusParam = statusFilter.map((status) => `&status=${status.value}`).join('')
      url += statusParam
    }

    if (sortColumn) {
      url += `&sortColumn=${sortColumn}`
      if (sortOrder) {
        url += `&sortOrder=${sortOrder}`
      }
    }

    await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res: AlertResponse) => {
        const updatedScans = res.alerts.map((alert) => {
          if (
            alert.scanMethod === ScanMethod.THREAT_HUNTER &&
            threatHunterUsers &&
            'threatHunterUsers' in threatHunterUsers
          ) {
            const threatHunterUser = threatHunterUsers.threatHunterUsers.find(
              (user) => user.accessCode === alert.scannedByAccessCode
            )
            if (threatHunterUser) {
              return { ...alert, scannedBy: threatHunterUser.email }
            }
          }
          return alert
        })
        dispatch(setCurrentPage(!pageNumber ? 0 : pageNumber - 1))
        dispatch(setAlerts(updatedScans))
        dispatch(setTotalPages(res.totalPages))
      })
      .catch((err) => {
        Sentry.captureMessage(err.message)
      })
  }

export const closeAlert = (alertId: number, scanId: string) => async () => {
  const url = API_URL + ALERTS_CLOSE_ROUTE

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    credentials: 'include',
    body: JSON.stringify({ id: alertId, scanId: scanId }),
  })
    .then(() => {
      return true
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return false
    })
}

export const generateAndDownloadReport = (scanId: string, isThreatHunter: boolean) => () => {
  const url = API_URL + ALERT_REPORT_ROUTE

  return new Promise((resolve, reject) => {
    const pollForReport = async () => {
      return fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        credentials: 'include',
        body: JSON.stringify({ scanId: scanId, isThreatHunter: isThreatHunter }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.message.includes('Report already exists')) {
            // Download the report
            const link = document.createElement('a')
            link.href = res.download_url
            link.download = ''
            link.style.display = 'none'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            toast.success('Report has been successfully generated', { duration: 6000 })
            resolve(true)
          } else if (res.statusCode !== 200) {
            toast.error('There was an issue generating the report', { duration: 6000 })
            reject(false)
          } else {
            setTimeout(pollForReport, 10000)
          }
        })
        .catch(() => {
          toast.error('There was an issue generating the report', { duration: 6000 })
          reject(false)
        })
    }
    pollForReport()
  })
}
