import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { addDomainSchema } from '../../schemas/SettingsSchema'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../reducers/store'
import { Button } from '@radix-ui/themes'
import { AddDomainInputs } from '../../types/BlocklistTypes'
import { getBlocklist, postDomainsToBlocklist, selectBlocklist } from '../../reducers/blocklist'
import toast from 'react-hot-toast'
import { Input } from '../../stories/input/Input'
import { IconCircleMinus } from '@tabler/icons-react'
import { useSelector } from 'react-redux'

interface IAddDomainModalProps {
  setShowModal: (show: boolean) => void
  csvDomainsToBlock: AddDomainInputs[]
  setCsvDomainsToBlock: (domains: AddDomainInputs[]) => void
}

function AddDomainModal(props: IAddDomainModalProps) {
  const { setShowModal, csvDomainsToBlock, setCsvDomainsToBlock } = props
  const { blocklist } = useSelector(selectBlocklist)
  const [loading, setLoading] = useState(false)
  const [domainsToBlock, setDomainsToBlock] = useState<AddDomainInputs[]>([])
  const di = useAppDispatch()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AddDomainInputs>({ resolver: yupResolver(addDomainSchema) })

  useEffect(() => {
    if (csvDomainsToBlock.length > 0) {
      setDomainsToBlock(csvDomainsToBlock)
    }
  }, [csvDomainsToBlock])

  const onSubmit: SubmitHandler<AddDomainInputs> = async (domainInfo) => {
    if (
      !domainsToBlock.some((domain) => domain.domain.toLowerCase() === domainInfo.domain.toLowerCase()) &&
      !(blocklist?.domains ?? []).some((blocked) => blocked.domain.toLowerCase() === domainInfo.domain.toLowerCase())
    ) {
      setDomainsToBlock([...domainsToBlock, domainInfo])
      reset()
    } else {
      toast.error('Domain already added', { duration: 6000 })
    }
  }

  const removeDomain = (domainToRemove: string) => {
    const newMembers = domainsToBlock.filter((domain) => domain.domain !== domainToRemove)
    setDomainsToBlock(newMembers)
  }

  const uploadDomainsToBlock = async () => {
    setLoading(true)
    const successful = await di(postDomainsToBlocklist(domainsToBlock))
    if (!successful) {
      toast.error(`Failed to add domain${domainsToBlock.length === 1 ? '' : 's'}, please try again`, { duration: 6000 })
      setLoading(false)
      return
    }
    toast.success(`Successfully added domain${domainsToBlock.length === 1 ? '' : 's'}`, { duration: 6000 })
    await di(getBlocklist())
    reset()
    setShowModal(false)
  }

  return (
    <div
      className="bg-black bg-opacity-50 absolute top-0 left-0 w-full h-full z-50"
      onClick={() => setShowModal(false)}
    >
      <div
        className="fixed w-[724px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 max-h-[70%] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <>
          <h2 className="text-xl font-bold mb-2">Add Domain</h2>
          <form onSubmit={handleSubmit(onSubmit)} className="block mt-4">
            <div className="flex">
              <div className="mr-2 w-52">
                <label className="text-ivyBlack font-semibold text-xs block">Name</label>
                <Input
                  type="text"
                  placeholder="Phishing CDN"
                  {...register('name', { required: true })}
                  onChange={undefined}
                  maxLength={50}
                />
                {errors.name && <p className="text-ErrorRed">{errors.name.message}</p>}
              </div>
              <div className="mr-2 w-52">
                <label className="text-ivyBlack font-semibold text-xs block">Domain</label>
                <Input
                  type="text"
                  placeholder="cdn.example.com"
                  {...register('domain', { required: true })}
                  onChange={undefined}
                />
                {errors.domain && <p className="text-ErrorRed">{errors.domain.message}</p>}
              </div>
              <Button
                type="submit"
                className="w-[84px] h-10 bg-neutral-12 rounded-lg mt-[17px] ml-4 p-2.5 text-white font-semibold text-sm cursor-pointer"
                value="Add"
              >
                Add
              </Button>
            </div>
          </form>
          <hr className="my-6 border-t border-DarkNavy/10" />

          <table className="table-auto w-full my-4 min-h-10">
            <tbody>
              {domainsToBlock.map((item: AddDomainInputs) => (
                <tr key={item.domain}>
                  <td className="px-4 py-2">{item.name}</td>
                  <td className="px-4 py-2">{item.domain}</td>
                  <td className="py-2 text-right w-6">
                    <IconCircleMinus
                      size={24}
                      color={'#1c2024'}
                      className="cursor-pointer ml-auto"
                      onClick={() => removeDomain(item.domain)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <hr className="my-6 border-t border-DarkNavy/10" />

          <div className="flex justify-end mt-6">
            <button
              className="px-4 py-2 mr-2 bg-gray-300 text-neutral-11 rounded"
              onClick={() => {
                setCsvDomainsToBlock([])
                setShowModal(false)
              }}
            >
              Cancel
            </button>
            <Button
              className={`h-10 px-4 py-2 bg-PurpleBlue-10 text-white ${domainsToBlock.length === 0 || loading ? 'opacity-50' : 'cursor-pointer'}`}
              onClick={() => {
                uploadDomainsToBlock()
              }}
              disabled={domainsToBlock.length === 0 || loading}
            >
              {!loading ? 'Block Domains' : 'Loading...'}
            </Button>
          </div>
        </>
      </div>
    </div>
  )
}

export default AddDomainModal
