import { DeviceInformation } from '../../types/DeviceTypes'

interface IDropDownProps {
  clickedInputs: DeviceInformation[]
  setShowRemoveDeviceModal: (show: boolean) => void
  removeUserDevices: () => void
}

function DeviceRemoveModal(props: IDropDownProps) {
  const { clickedInputs, setShowRemoveDeviceModal, removeUserDevices } = props

  return (
    <div
      className="bg-black bg-opacity-50 absolute top-0 left-0 w-full h-full z-50"
      onClick={() => setShowRemoveDeviceModal(false)}
    >
      <div
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 max-h-[50%] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-xl font-bold mb-4">Remove Member Devices</h2>
        <p className="mb-4">The following individual(s) devices will be removed:</p>
        <p className="mb-4 text-sm text-ErrorRed">
          Please note: listed devices will promptly lose iVerify access and their code will be invalidated.
        </p>
        <ul className="list-disc pl-6 mb-4">
          {clickedInputs.map((item: DeviceInformation) => (
            <li key={item.code}>{item.name}</li>
          ))}
        </ul>
        <div className="flex justify-end">
          <button className="px-4 py-2 mr-2 bg-gray-300 rounded-lg" onClick={() => setShowRemoveDeviceModal(false)}>
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-ErrorRed text-white rounded-lg"
            onClick={() => {
              removeUserDevices()
            }}
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  )
}

export default DeviceRemoveModal
