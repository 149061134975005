import { IconCircleMinus, IconFileDownload } from '@tabler/icons-react'
import DropZone from '../drop-zone/DropZone'
import { Button } from '@radix-ui/themes'

interface ICsvUploadPageProps {
  setCurrentPage: (page: string) => void
  uploadedFiles: File[]
  setUploadedFiles: (files: File[]) => void
  setShowCSVModal: (show: boolean) => void
}

function CsvUploadPage(props: ICsvUploadPageProps) {
  const { setCurrentPage, uploadedFiles, setUploadedFiles, setShowCSVModal } = props

  const TemplateLink = () => (
    <a
      className="flex justify-center text-PurpleBlue-10 font-semibold"
      href="data:text/csv;charset=UTF-8,fullname%2Cemail%0AJohn%20Smith%2Cperson%40yourOrganization.com"
      download="member_template.csv"
    >
      <button className="flex px-4 py-2 mr-2 bg-gray-300 text-neutral-11 rounded">
        <IconFileDownload size={24} className="mr-2" /> <span className="font-normal">Download CSV Template</span>
      </button>
    </a>
  )

  return (
    <>
      <DropZone
        setUploadedFiles={setUploadedFiles}
        customMessage={'File must include “fullname” and “email” columns'}
      />

      <table className="table-auto w-full my-4 min-h-10">
        <tbody>
          {uploadedFiles.map((file: File) => (
            <tr key={file.name}>
              <td className="px-4 py-2">{file.name}</td>
              <td className="py-2 text-right w-6">
                <IconCircleMinus
                  size={24}
                  color={'#1c2024'}
                  className="cursor-pointer ml-auto"
                  onClick={() => setUploadedFiles([])}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <hr className="mb-6 mt-3 border-t border-DarkNavy/10" />
      <div className="flex justify-between mt-6">
        {TemplateLink()}
        <div className="flex justify-end">
          <button className="px-4 py-2 mr-2 bg-gray-300 text-neutral-11 rounded" onClick={() => setShowCSVModal(false)}>
            Cancel
          </button>
          <Button
            className={`h-10 px-4 py-2 bg-PurpleBlue-10 text-white ${uploadedFiles.length === 0 ? 'opacity-50' : 'cursor-pointer'}`}
            disabled={uploadedFiles.length === 0}
            onClick={() => setCurrentPage('INVITE')}
          >
            Upload
          </Button>
        </div>
      </div>
    </>
  )
}

export default CsvUploadPage
