import { ScanMethod } from './AlertTypes'

export interface Activity {
  scanId: string
  memberName: number
  createdAt: string
  model: string
  accessCode: string
  scanType: ScanMethod
  alertCreated: boolean
  scanError?: ScanError
}

export interface ActivityResponse {
  scans: Activity[]
  totalPages: number
}

export interface ActivityPopoutRequest {
  scanId: string
  scanType: string
}

export interface EdrScanDetails {
  scanId: string
  memberName: string
  createdAt: string
  model: string
  accessCode: string
  scanType: string
  alertCreated: boolean
  biometrics: boolean
  isNotificationsEnabled: boolean
  isPlayProtectEnabled: boolean
  isSideloadingEnabled: boolean
  isDeveloperModeEnabled: boolean
  isWorkProfile: boolean
  isAdbEnabled: boolean
  osVersion: string
  securityPatch: string
  screenLock: boolean
  lockdownEnabled: boolean
  smishingEnabled: boolean
  details: string
  platform: string
}

export interface DeepScanDetails {
  scanId: string
  memberName: string
  createdAt: string
  model: string
  accessCode: string
  scanType: string
  alertCreated: boolean
  details: string
  platform: string
  scannedBy: string
  iosVersion: string
  scanError?: ScanError
}

export enum ScanError {
  UNSUPPORTED_FILE_TYPE = 'UNSUPPORTED_FILE_TYPE',
  FILE_PROCESSING_ERROR = 'FILE_PROCESSING_ERROR',
  UNKOWN_ERROR = 'UNKOWN_ERROR',
  INVALID_STORAGE_PATH = 'INVALID_STORAGE_PATH',
}

export interface Threat {
  threat: string
}
