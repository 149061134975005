/* eslint-disable @typescript-eslint/no-explicit-any */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { DashboardStats, DeviceInformation, InsecureDeviceInformation, Stats } from '../../types/DeviceTypes'

export interface DeviceState {
  devices: DeviceInformation[] | undefined
  totalPages: number
  currentPage: number
  insecureDevices: InsecureDeviceInformation[] | undefined
  dashboardDeviceStats: DashboardStats | undefined
  dashboardDevicesCreatedStat: Stats | undefined
  dashboardAlertsCreatedStat: Stats | undefined
  dashboardTotalScansStat: Stats | undefined
  dashboardInsecureDevicesStat: Stats | undefined
}

const initialState: DeviceState = {
  devices: undefined,
  totalPages: 1,
  currentPage: 1,
  insecureDevices: undefined,
  dashboardDeviceStats: undefined,
  dashboardDevicesCreatedStat: undefined,
  dashboardAlertsCreatedStat: undefined,
  dashboardTotalScansStat: undefined,
  dashboardInsecureDevicesStat: undefined,
}

export const deviceSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setDevices(state, action: PayloadAction<any>) {
      state.devices = action.payload
    },
    setTotalPages(state, action: PayloadAction<any>) {
      state.totalPages = action.payload
    },
    setCurrentPage(state, action: PayloadAction<any>) {
      state.currentPage = action.payload
    },
    setInsecureDevices(state, action: PayloadAction<any>) {
      state.insecureDevices = action.payload
    },
    setDashboardDeviceStats(state, action: PayloadAction<any>) {
      state.dashboardDeviceStats = action.payload
    },
    setDashboardDevicesCreatedStat(state, action: PayloadAction<any>) {
      state.dashboardDevicesCreatedStat = action.payload
    },
    setDashboardAlertsCreatedStat(state, action: PayloadAction<any>) {
      state.dashboardAlertsCreatedStat = action.payload
    },
    setDashboardTotalScansStat(state, action: PayloadAction<any>) {
      state.dashboardTotalScansStat = action.payload
    },
    setDashboardInsecureDevicesStat(state, action: PayloadAction<any>) {
      state.dashboardInsecureDevicesStat = action.payload
    },
  },
})
export const selectDevices = (state: RootState) => state.devices

export const {
  setDevices,
  setTotalPages,
  setCurrentPage,
  setInsecureDevices,
  setDashboardDeviceStats,
  setDashboardDevicesCreatedStat,
  setDashboardAlertsCreatedStat,
  setDashboardTotalScansStat,
  setDashboardInsecureDevicesStat,
} = deviceSlice.actions
export default deviceSlice.reducer
