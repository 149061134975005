import { ReactNode } from 'react'

const Scroll = ({ children, classNames }: { children: ReactNode, classNames?: string }) => {
  return (
    <div className={`${classNames} overflow-y-auto [&::-webkit-scrollbar-thumb]:cursor-pointer [&::-webkit-scrollbar-track]:cursor-pointer [&::-webkit-scrollbar]:h-3 [&::-webkit-scrollbar]:w-2  [&::-webkit-scrollbar-track]:bg-neutral-2 [&::-webkit-scrollbar-thumb]:bg-neutral-5 [&::-webkit-scrollbar-track]:rounded-full [&::-webkit-scrollbar-thumb]:rounded-full whitespace-nowrap`}>
      {children}
    </div>
  )
}

export default Scroll
