import { DesktopIcon, InfoCircledIcon, MobileIcon, ReaderIcon } from '@radix-ui/react-icons'
import { Alert, ScanMethod, Severity } from '../../types/AlertTypes'
import { Badge, Button, Tooltip } from '@radix-ui/themes'
import { createColumnHelper } from '@tanstack/react-table'
import { convertTimestampToIverifyDate } from '../../utils/dates'
import { useState } from 'react'
import LoadingIcon from '../../assets/loading.svg'
import CloseIcon from '../../assets/close-circle.svg'
import { IconFileDownload } from '@tabler/icons-react'
import Scroll from '../scroll/Scroll'

export const generateMethodView = (status: ScanMethod) => {
  switch (status) {
    case ScanMethod.IVY_APP:
      return (
        <div className="flex">
          <MobileIcon className="mt-0.5 mr-2" />
          <p>EDR App</p>
        </div>
      )
    case ScanMethod.THREAT_HUNTER:
      return (
        <div className="flex">
          <DesktopIcon className="mt-0.5 mr-2" />
          <p>Threat Hunter</p>
        </div>
      )
    case ScanMethod.APP_SYSDIAGNOSE:
      return (
        <div className="flex">
          <ReaderIcon className="mt-0.5 mr-2" />
          <p>Sysdiagnose</p>
        </div>
      )
    case ScanMethod.APP_BUG_REPORT:
      return (
        <div className="flex">
          <ReaderIcon className="mt-0.5 mr-2" />
          <p>Bug Report</p>
        </div>
      )
    case ScanMethod.IVERIFY_ELITE:
      return (
        <div className="flex">
          <MobileIcon className="mt-0.5 mr-2" />
          <p>Elite Scan</p>
        </div>
      )
    default:
      return null
  }
}

const checkIfUpdated = (updatedAt: string, createdAt: string) => {
  return updatedAt ? convertTimestampToIverifyDate(updatedAt, true) : convertTimestampToIverifyDate(createdAt, true)
}

export const alertColumns = (
  setShowPopout: (show: boolean) => void,
  setPopoutData: (deviceInfo: Alert) => void,
  downloadAlertReport: (scanId: string, isThreatHunter: boolean) => Promise<void>
) => {
  const columnHelper = createColumnHelper<Alert>()

  return [
    columnHelper.accessor('alertName', {
      header: 'Name',
      maxSize: 100,
      enableSorting: true,
    }),
    columnHelper.accessor('severity', {
      header: 'Severity',
      cell: (row) => {
        return generateSeverityView(row.row.original.severity)
      },
      enableSorting: true,
    }),
    columnHelper.accessor('alertStatus', {
      header: 'Status',
      enableSorting: true,
    }),
    columnHelper.accessor('memberName', {
      header: 'Device Name',
      enableSorting: true,
      cell: (row) => {
        if (row.row.original.scanMethod === ScanMethod.THREAT_HUNTER) {
          return (
            <Tooltip
              content="Unique device identifier associated with the scan"
              side="top"
              align="end"
              className="max-w-[240px]"
            >
              <Scroll classNames="my-2 pb-1">
                <div className="flex">
                  <InfoCircledIcon className="h-4 w-4 mr-1 mt-0.5" />
                  <p>{row.row.original.memberName}</p>
                </div>
              </Scroll>
            </Tooltip>
          )
        } else {
          return <Scroll classNames="my-2 pb-1">{row.row.original.memberName || 'Unknown'}</Scroll>
        }
      },
    }),
    columnHelper.accessor('device', {
      header: 'Device',
      enableSorting: false,
      cell: (row) => {
        return row.row.original.device !== 'null' ? row.row.original.device : 'Unknown'
      },
    }),
    columnHelper.accessor('scannedByAccessCode', {
      header: 'Scanned By Code',
      enableSorting: true,
      cell: (row) => {
        if (row.row.original.scanMethod === ScanMethod.THREAT_HUNTER) {
          return (
            <Tooltip
              content="Access code of the Threat Hunter user that initiated the scan"
              side="top"
              align="end"
              className="max-w-[240px]"
            >
              <div className="flex">
                <InfoCircledIcon className="h-4 w-4 mr-1 mt-0.5" />
                <p>{row.row.original.scannedByAccessCode}</p>
              </div>
            </Tooltip>
          )
        } else {
          return row.row.original.scannedByAccessCode || 'Unknown'
        }
      },
    }),
    columnHelper.accessor('scanMethod', {
      header: 'Scan Method',
      enableSorting: true,
      cell: (row) => {
        return generateMethodView(row.row.original.scanMethod)
      },
    }),
    columnHelper.accessor('updatedAt', {
      cell: (row) => checkIfUpdated(row.row.original.updatedAt, row.row.original.createdAt),
      header: 'Last Seen',
      enableSorting: true,
    }),
    {
      id: 'button',
      header: 'Report',
      cell: (row: { row: { original: Alert } }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [loading, setLoading] = useState(false)

        const handleClick = () => {
          setLoading(true)
          const isThreatHunter = row.row.original.scanMethod === ScanMethod.THREAT_HUNTER
          downloadAlertReport(row.row.original.scanId, isThreatHunter).finally(() => setLoading(false))
        }

        return (
          <>
            {(row.row.original.scanMethod === ScanMethod.IVY_APP ||
              row.row.original.scanMethod === ScanMethod.APP_BUG_REPORT) && (
              <Tooltip
                content="Reports are available only for Threat Hunter, Elite, and iOS Forensic Scans."
                side="top"
                align="end"
                className="max-w-[240px]"
              >
                <div className="h-10 w-10 flex justify-center items-center">
                  <img src={CloseIcon} alt="Download" className="h-8 w-8" />
                </div>
              </Tooltip>
            )}
            {row.row.original.scanMethod !== ScanMethod.IVY_APP &&
              row.row.original.scanMethod !== ScanMethod.APP_BUG_REPORT && (
                <>
                  {loading ? (
                    <div className="h-10 w-10 flex justify-center items-center">
                      <img src={LoadingIcon} alt="Download" className="h-8 w-8 text-PurpleBlue-11 animate-spinSlow" />
                    </div>
                  ) : (
                    <div className="h-10 w-10 flex justify-center items-center hover:bg-DownloadButtonPurple rounded cursor-pointer">
                      <IconFileDownload stroke={1.5} className="h-8 w-8 text-PurpleBlue-11" onClick={handleClick} />
                    </div>
                  )}
                </>
              )}
          </>
        )
      },
    },
    {
      id: 'button',
      cell: (row: { row: { original: Alert } }) => (
        <Button
          size={'2'}
          className="cursor-pointer float-right"
          onClick={() => {
            setPopoutData(row.row.original)
            setShowPopout(true)
          }}
          variant="outline"
        >
          View
        </Button>
      ),
    },
  ]
}

export const generateSeverityView = (status: Severity) => {
  switch (status) {
    case 'CRITICAL':
      return (
        <div className="flex">
          <Badge color="crimson">Critical</Badge>
        </div>
      )
    case 'HIGH':
      return (
        <div className="flex">
          <Badge color="orange">High</Badge>
        </div>
      )
    case 'SUSPICIOUS':
      return (
        <div className="flex">
          <Badge color="orange">Suspicious</Badge>
        </div>
      )
    case 'LOW':
      return (
        <div className="flex">
          <Badge color="indigo">Low</Badge>
        </div>
      )
    default:
      return null
  }
}
