import { Button, IconButton, Separator, Text } from '@radix-ui/themes'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getConditionalAccessPolicy,
  postConditionalAccessConfig,
  putConditionalAccessPolicy,
  selectSettings,
  setConditionalAccess,
} from '../../reducers/settings'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { addConditionalAccessSchema } from '../../schemas/SettingsSchema'
import { useAppDispatch } from '../../reducers/store'
import toast from 'react-hot-toast'
import { EyeNoneIcon, EyeOpenIcon } from '@radix-ui/react-icons'
import { ConditionalAccessPolicyResponse } from '../../types/SettingsType'
import { Input } from '../../stories/input/Input'

type conditionalAccessInputs = {
  tenant: string
  clientId: string
  clientSecret: string
  userExtension: string
  groupId: string
  provider: string
}

interface IConditionAccessOptionsProps {
  setScreenToShow: (screen: string) => void
  setShowRevokeModal: (show: boolean) => void
}

function ConditionAccessOptions(props: IConditionAccessOptionsProps) {
  const { setScreenToShow, setShowRevokeModal } = props
  const di = useAppDispatch()
  const dispatch = useDispatch()
  const [showClientSecret, setShowClientSecret] = useState<boolean>(false)
  const [showPolicy, setShowPolicy] = useState<boolean>(false)
  const [alertSeverity, setAlertSeverity] = useState<string>('NONE')
  const [deviceIntegrityPeriod, setDeviceIntegrityPeriod] = useState<string>('NEVER')
  const [outdatedOSPeriod, setOutdatedOSPeriod] = useState<string>('NEVER')
  const [screenLockEnabled, setScreenLockEnabled] = useState<string>('DISABLED')
  const [smishingEnabled, setSmishingEnabled] = useState<string>('DISABLED')
  const [biometricsEnabled, setBiometricsEnabled] = useState<string>('DISABLED')
  const { conditionalAccess, conditionalAccessPolicy } = useSelector(selectSettings)

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<conditionalAccessInputs>({ resolver: yupResolver(addConditionalAccessSchema) })

  const tenantValue = watch('provider')

  const onSubmit: SubmitHandler<conditionalAccessInputs> = async (data) => {
    const dataToPost = {
      ...data,
      active: true,
    }
    const successfulSave = await di(postConditionalAccessConfig(dataToPost))
    if (successfulSave.error === undefined) {
      dispatch(setConditionalAccess(dataToPost))
      di(getConditionalAccessPolicy())
      toast.success('Conditional Access Saved', { duration: 6000 })
    } else {
      toast.error(successfulSave.error, { duration: 6000 })
    }
  }

  useEffect(() => {
    if (conditionalAccess) {
      setValue('tenant', conditionalAccess.tenant)
      setValue('provider', conditionalAccess.provider)
      setValue('clientId', conditionalAccess.clientId)
      setValue('clientSecret', conditionalAccess.clientSecret)
      setValue('userExtension', conditionalAccess.userExtension)
      setValue('groupId', conditionalAccess.groupId)
    }
  }, [conditionalAccess])

  useEffect(() => {
    if (conditionalAccessPolicy) {
      setShowPolicy(true)
      setAlertSeverity(conditionalAccessPolicy.alertSeverity)
      setDeviceIntegrityPeriod(conditionalAccessPolicy.deviceIntegrityPeriod)
      setOutdatedOSPeriod(conditionalAccessPolicy.outdatedOSPeriod)
      setScreenLockEnabled(conditionalAccessPolicy.screenLockEnabled ? 'ENABLED' : 'DISABLED')
      setSmishingEnabled(conditionalAccessPolicy.smishingEnabled ? 'ENABLED' : 'DISABLED')
      setBiometricsEnabled(conditionalAccessPolicy.biometricsEnabled ? 'ENABLED' : 'DISABLED')
    }
  }, [conditionalAccessPolicy])

  const stateSetters: { [key: string]: Dispatch<SetStateAction<string>> } = {
    alertSeverity: setAlertSeverity,
    deviceIntegrityPeriod: setDeviceIntegrityPeriod,
    outdatedOSPeriod: setOutdatedOSPeriod,
    screenLockEnabled: setScreenLockEnabled,
    smishingEnabled: setSmishingEnabled,
    biometricsEnabled: setBiometricsEnabled,
  }

  const updatePolicy = async (key: string, value: string) => {
    let payload: ConditionalAccessPolicyResponse = {
      alertSeverity,
      deviceIntegrityPeriod,
      outdatedOSPeriod,
      screenLockEnabled,
      smishingEnabled,
      biometricsEnabled,
    }
    payload = {
      ...payload,
      [key]: value,
    }
    const successfulSave = await di(putConditionalAccessPolicy(payload))

    if (successfulSave) {
      toast.success('Conditional Access Policy Updated', { duration: 6000 })
      if (stateSetters[key]) {
        stateSetters[key](value)
      }
    } else {
      toast.error('Failed to update Conditional Access Policy, please try again.', { duration: 6000 })
    }
  }

  const showRevokeConditionalAccess = () => {
    setScreenToShow('CONDITIONAL_ACCESS')
    setShowRevokeModal(true)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Separator my="3" size="4" />
      <div className="flex mt-2 justify-between">
        <div className="w-full mr-4">
          <Text as="div" size="1" className="mt-2" color="gray">
            IDENTITY PROVIDER
          </Text>
          <select
            className="h-10 w-full flex mt-1 border-[#00000027] border rounded px-2 py-2.5 justify-between text-sm"
            {...register('provider', { required: true })}
          >
            <option value="OKTA">Okta</option>
            <option value="AZURE">Azure</option>
          </select>
          {errors.provider && <p className="text-ErrorRed">{errors.provider.message}</p>}
        </div>
        <div className="w-full mr-4">
          <Text as="div" size="1" className="mt-2" color="gray">
            {tenantValue === 'OKTA' ? 'TENANT (OKTA DOMAIN)' : 'TENANT'}
          </Text>
          <Input type="text" {...register('tenant', { required: true })} onChange={undefined} />
          {errors.tenant && <p className="text-ErrorRed">{errors.tenant.message}</p>}
        </div>
        <div className="w-full mr-4">
          <Text as="div" size="1" className="mt-2" color="gray">
            GROUP ID
          </Text>
          <Input type="text" {...register('groupId', { required: true })} onChange={undefined} />
          {errors.groupId && <p className="text-ErrorRed">{errors.groupId.message}</p>}
        </div>
      </div>
      <div className="flex mt-2 justify-between">
        <div className="w-full mr-4">
          <Text as="div" size="1" className="mt-2" color="gray">
            CLIENT ID
          </Text>
          <Input type="text" {...register('clientId', { required: true })} onChange={undefined} />
          {errors.clientId && <p className="text-ErrorRed">{errors.clientId.message}</p>}
        </div>
        <div className="w-full mr-4 relative">
          <Text as="div" size="1" className="mt-2" color="gray">
            {tenantValue === 'OKTA' ? 'APPLICATION PRIVATE KEY (JSON)' : 'CLIENT SECRET'}
          </Text>
          <Input
            type={showClientSecret ? 'text' : 'password'}
            {...register('clientSecret', { required: true })}
            onChange={undefined}
            hasButton={true}
          />
          <div
            className="absolute inset-y-0 right-0 pr-3 mt-[25px] flex items-center cursor-pointer"
            onClick={(event) => {
              event.preventDefault()
              setShowClientSecret(!showClientSecret)
            }}
          >
            <IconButton size="2" variant="ghost">
              {showClientSecret ? <EyeNoneIcon className="h-5 w-5" /> : <EyeOpenIcon className="h-5 w-5" />}
            </IconButton>
          </div>
          {errors.clientSecret && <p className="text-ErrorRed">{errors.clientSecret.message}</p>}
        </div>
        <div className="w-full mr-4">
          <Text as="div" size="1" className="mt-2" color="gray">
            USER EXTENSION
          </Text>
          <Input type="text" {...register('userExtension', { required: true })} onChange={undefined} />
          {errors.userExtension && <p className="text-ErrorRed">{errors.userExtension.message}</p>}
        </div>
      </div>
      <Text as="div" color="gray" size="2" className="mt-2">
        Read{' '}
        <span className="text-PurpleBlue-10 cursor-pointer">
          <a href="https://docs.iverify.io/conditional-access-management" target="_blank">
            Conditional Access Setup Documentation
          </a>
        </span>
      </Text>
      <div className="flex w-2/3 mt-4">
        <Button className="w-1/2 mr-2 cursor-pointer" type="submit">
          Save
        </Button>
        <Button
          className="w-1/2 bg-ErrorRed cursor-pointer"
          onClick={(event) => {
            event.preventDefault()
            showRevokeConditionalAccess()
          }}
        >
          Remove Conditional Access
        </Button>
      </div>
      {showPolicy && conditionalAccess && (
        <>
          <hr className="w-full my-4 h-[1px] bg-grey-400" />
          <div className="block w-full text-sm">
            <div className="flex justify-between">
              <div className="block">
                <p className="font-semibold">Quarantine on alert severity</p>
                <p>Configure when devices are blocked based on alert severity</p>
              </div>
              <select
                className="h-10 w-40 flex mt-1 border-[#00000027] border rounded px-2 py-2.5 justify-between text-sm"
                value={alertSeverity}
                onChange={(e) => updatePolicy('alertSeverity', e.target.value)}
                defaultValue={'NONE'}
              >
                <option value="CRITICAL_ONLY">Critical Only</option>
                <option value="HIGH_AND_CRITICAL">High and Critical</option>
                <option value="NONE">None</option>
              </select>
            </div>
            <div className="flex justify-between mt-6">
              <div className="block">
                <p className="font-semibold">Quarantine on outdated OS</p>
                <p>Configure the time period devices are blocked based on when they were outdated</p>
              </div>
              <select
                className="h-10 w-40 flex mt-1 border-[#00000027] border rounded px-2 py-2.5 justify-between text-sm"
                value={outdatedOSPeriod}
                onChange={(e) => updatePolicy('outdatedOSPeriod', e.target.value)}
                defaultValue={'NEVER'}
              >
                <option value="THREE_DAYS">3 Days</option>
                <option value="SEVEN_DAYS">7 Days</option>
                <option value="TWO_WEEKS">2 Weeks</option>
                <option value="NEVER">Never</option>
              </select>
            </div>
            <div className="flex justify-between mt-6">
              <div className="block">
                <p className="font-semibold">Quarantine when no scans reported</p>
                <p>Configure the time period devices are blocked for not reporting a scan</p>
              </div>
              <select
                className="h-10 w-40 flex mt-1 border-[#00000027] border rounded px-2 py-2.5 justify-between text-sm"
                value={deviceIntegrityPeriod}
                onChange={(e) => updatePolicy('deviceIntegrityPeriod', e.target.value)}
                defaultValue={'NEVER'}
              >
                <option value="THREE_DAYS">3 Days</option>
                <option value="SEVEN_DAYS">7 Days</option>
                <option value="TWO_WEEKS">2 Weeks</option>
                <option value="NEVER">Never</option>
              </select>
            </div>
            <div className="flex justify-between mt-6">
              <div className="block">
                <p className="font-semibold">Quarantine when no screen lock present</p>
                <p>Configure if devices with no screen lock set up are blocked</p>
              </div>
              <select
                className="h-10 w-40 flex mt-1 border-[#00000027] border rounded px-2 py-2.5 justify-between text-sm"
                value={screenLockEnabled}
                onChange={(e) => updatePolicy('screenLockEnabled', e.target.value)}
                defaultValue={'DISABLED'}
              >
                <option value="ENABLED">Enabled</option>
                <option value="DISABLED">Disabled</option>
              </select>
            </div>
            <div className="flex justify-between mt-6">
              <div className="block">
                <p className="font-semibold">Quarantine when smishing is not enabled</p>
                <p>Configure if devices with smishing protection that has not been set up are blocked</p>
              </div>
              <select
                className="h-10 w-40 flex mt-1 border-[#00000027] border rounded px-2 py-2.5 justify-between text-sm"
                value={smishingEnabled}
                onChange={(e) => updatePolicy('smishingEnabled', e.target.value)}
                defaultValue={'DISABLED'}
              >
                <option value="ENABLED">Enabled</option>
                <option value="DISABLED">Disabled</option>
              </select>
            </div>
            <div className="flex justify-between mt-6">
              <div className="block">
                <p className="font-semibold">Quarantine when no biometrics are present</p>
                <p>Configure if devices with no biometrics set up are blocked</p>
              </div>
              <select
                className="h-10 w-40 flex mt-1 border-[#00000027] border rounded px-2 py-2.5 justify-between text-sm"
                value={biometricsEnabled}
                onChange={(e) => updatePolicy('biometricsEnabled', e.target.value)}
                defaultValue={'DISABLED'}
              >
                <option value="ENABLED">Enabled</option>
                <option value="DISABLED">Disabled</option>
              </select>
            </div>
          </div>
        </>
      )}
    </form>
  )
}

export default ConditionAccessOptions
