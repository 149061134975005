export type AlertData = {
  device_name: string
  device: string
  scan_details: string
  scan_method: ScanMethod
  status: Status
  action: Action
}

export enum ScanMethod {
  THREAT_HUNTER = 'Threat Hunter',
  IV_ORGS = 'iV Orgs',
}

export enum Status {
  MALICIOUS = 'Malicious',
  SUSPICIOUS = 'Suspicious',
}

export enum Action {
  AWAITING_INVESTIGATION = 'Awaiting Investigation',
  BEING_INVESTIGATED = 'Being Investigated',
}

export interface Devices {
  devices: DeviceInformation[]
  totalPages: number
}

export interface EmailUserCount {
  count: string
}

export interface DeviceInformation extends DeviceRegistration {
  id: number
  secure: boolean
  screenLock: boolean
  biometrics: boolean
  version: string
  guidesCompleted: string
  device: string
  os: string
  securityPatchVersion: string
  isLatestVersion: boolean
  latestAppVersion: string
  osVersionState: OsVersionState
  isNotificationsEnabled: boolean
  latestInsecureScanDate: string
  platform: string
  latestOSVersion: string
  isWorkProfile: boolean
  latestScanDate: string
  isPlayProtectEnabled: boolean
  threats: ThreatFamily[]
  deviceName: string
  isLockdownEnabled?: boolean
  smishingEnabled?: boolean
  isSmishingEnabled?: boolean
}

export interface InsecureDeviceInformation {
  secure: boolean
  device: string
  platform: string
  name: string
  code: string
  added: number
  alertDescription: string
}

export interface DeviceRegistration {
  memberId: number
  name: string
  email: string
  code: string
  enrolled: boolean
  added: number
}

export type OsVersionState =
  | 'LATEST'
  | 'UNKNOWN'
  | 'LATEST_FOR_DEVICE'
  | 'OUT_OF_DATE'
  | 'SECURITY_PATCH_OUTDATED'
  | 'BETA'

export interface ThreatFamily {
  code: string
  description: string
}

export interface DashboardStats {
  devices: Stats
  scans: Stats
  alerts: Stats
  insecure: Stats
}

export interface Stats {
  total: number
  thirtyDayDiff: number
  diffState: DiffState
}

export enum DiffState {
  INCREASE = 'INCREASE',
  DECREASE = 'DECREASE',
  SAME = 'SAME',
}

export interface DeviceThreat {
  threat: string
}
