import { ArrowDownIcon, ArrowUpIcon, MinusIcon } from '@radix-ui/react-icons'
import { Card, Skeleton } from '@radix-ui/themes'
import { useEffect } from 'react'
import { useAppDispatch } from '../../reducers/store'
import {
  fetchAlertsCreatedStats,
  fetchDevicesCreatedStats,
  fetchInsecureDevicesStats,
  fetchTotalScansStats,
  selectDevices,
} from '../../reducers/devices'
import { useSelector } from 'react-redux'
import { DiffState } from '../../types/DeviceTypes'
import { formatLargeNumber } from '../../utils/number'

function DashSideStats() {
  const di = useAppDispatch()
  const {
    dashboardDevicesCreatedStat,
    dashboardAlertsCreatedStat,
    dashboardTotalScansStat,
    dashboardInsecureDevicesStat,
  } = useSelector(selectDevices)

  useEffect(() => {
    di(fetchDevicesCreatedStats())
    di(fetchAlertsCreatedStats())
    di(fetchTotalScansStats())
    di(fetchInsecureDevicesStats())
  }, [])

  const generatePositiveDiff = (statNumber: number, diffState: DiffState) => {
    let color
    let icon
    switch (diffState) {
      case DiffState.INCREASE:
        color = 'text-Green-9'
        icon = <ArrowUpIcon className="w-3 h-3 mt-0.5 text-Green-9" />
        break
      case DiffState.DECREASE:
        color = 'text-Red-9'
        icon = <ArrowDownIcon className="w-3 h-3 mt-0.5 text-Red-9" />
        break
      case DiffState.SAME:
        color = 'text-gray'
        icon = <MinusIcon className="w-3 h-3 mt-0.5 text-gray" />
        break
      default:
        color = 'text-gray'
        icon = <MinusIcon className="w-3 h-3 mt-0.5 text-gray" />
    }

    return (
      <div className="flex mt-1.5">
        {icon}
        <div className={`text-xs ${color}`}>{formatLargeNumber(statNumber)}</div>
      </div>
    )
  }

  const generateNegativeDiff = (statNumber: number, diffState: DiffState) => {
    let color
    let icon
    switch (diffState) {
      case DiffState.INCREASE:
        color = 'text-Red-9'
        icon = <ArrowUpIcon className="w-3 h-3 mt-0.5 text-Red-9" />
        break
      case DiffState.DECREASE:
        color = 'text-Green-9'
        icon = <ArrowDownIcon className="w-3 h-3 mt-0.5 text-Green-9" />
        break
      case DiffState.SAME:
        color = 'text-Green-9'
        icon = <MinusIcon className="w-3 h-3 mt-0.5 text-Green-9" />
        break
      default:
        color = 'text-Green-9'
        icon = <MinusIcon className="w-3 h-3 mt-0.5 text-Green-9" />
    }

    return (
      <div className="flex mt-1">
        {icon}
        <div className={`text-xs ${color}`}>{formatLargeNumber(statNumber)}</div>
      </div>
    )
  }

  const showLoadingSkeleton = () => {
    return (
      <div className="flex flex-col">
        <Skeleton className="h-7 w-20 mt-3" />
        <Skeleton className="h-4 w-36 mt-2" />
      </div>
    )
  }

  return (
    <>
      <div className="grid grid-cols-2 lg:grid-cols-4 w-full mb-8 border-0 m-0 p-0 bg-neutral-3">
        <Card size="2" className="my-0 bg-white mx-0 flex-grow pb-2 col-span-1" variant="ghost">
          <div className="flex justify-between">
            <p className="mr-1">Devices Created</p>
            <p className="text-xs text-Grey-11 mt-1 ml-1">Past 30 days</p>
          </div>

          {dashboardDevicesCreatedStat && (
            <>
              <p className="text-[28px] font-medium">{formatLargeNumber(dashboardDevicesCreatedStat.total)}</p>
              <div className="text-xs font-medium flex">
                {generatePositiveDiff(dashboardDevicesCreatedStat.thirtyDayDiff, dashboardDevicesCreatedStat.diffState)}
                <p className="text-xs text-Grey-11 mt-1.5 ml-1.5">From previous 30 days</p>
              </div>
            </>
          )}
          {!dashboardDevicesCreatedStat && showLoadingSkeleton()}
        </Card>
        <Card size="2" className="ml-4 my-0 bg-white mx-0 flex-grow pb-2 col-span-1" variant="ghost">
          <div className="flex justify-between">
            <p className="mr-1">Scans Created</p>
            <p className="text-xs text-Grey-11 mt-1 ml-1">Past 30 days</p>
          </div>
          {dashboardTotalScansStat && (
            <>
              <p className="text-[28px] font-medium">{formatLargeNumber(dashboardTotalScansStat.total)}</p>
              <div className="text-xs font-medium flex">
                {generatePositiveDiff(dashboardTotalScansStat.thirtyDayDiff, dashboardTotalScansStat.diffState)}
                <p className="text-xs text-Grey-11 mt-1.5 ml-1.5">From previous 30 days</p>
              </div>
            </>
          )}
          {!dashboardTotalScansStat && showLoadingSkeleton()}
        </Card>
        <Card
          size="2"
          className="ml-0 mt-4 mb-0 lg:my-0 lg:ml-4 bg-white mx-0 flex-grow pb-2 col-span-1"
          variant="ghost"
        >
          <div className="flex justify-between">
            <p className="mr-1">Alerts Created</p>
            <p className="text-xs text-Grey-11 mt-1 ml-1">Past 30 days</p>
          </div>
          {dashboardAlertsCreatedStat && (
            <>
              <p className="text-[28px] font-medium">{formatLargeNumber(dashboardAlertsCreatedStat.total)}</p>
              <div className="text-xs font-medium flex">
                {generateNegativeDiff(dashboardAlertsCreatedStat.thirtyDayDiff, dashboardAlertsCreatedStat.diffState)}
                <p className="text-xs text-Grey-11 mt-1 ml-1.5">From previous 30 days</p>
              </div>
            </>
          )}
          {!dashboardAlertsCreatedStat && showLoadingSkeleton()}
        </Card>
        <Card size="2" className="ml-4 mt-4 mb-0 lg:my-0 bg-white mx-0 flex-grow pb-2 col-span-1" variant="ghost">
          <div className="flex justify-between">
            <p className="mr-1">Insecure Devices</p>
            <p className="text-xs text-Grey-11 mt-1 ml-1">Past 30 days</p>
          </div>
          {dashboardInsecureDevicesStat && (
            <>
              <p className="text-[28px] font-medium">{formatLargeNumber(dashboardInsecureDevicesStat.total)}</p>
              <div className="text-xs font-medium flex">
                {generateNegativeDiff(
                  dashboardInsecureDevicesStat.thirtyDayDiff,
                  dashboardInsecureDevicesStat.diffState
                )}
                <p className="text-xs text-Grey-11 mt-1 ml-1.5">From previous 30 days</p>
              </div>
            </>
          )}
          {!dashboardInsecureDevicesStat && showLoadingSkeleton()}
        </Card>
      </div>
    </>
  )
}

export default DashSideStats
