import { CopyIcon, Cross2Icon } from '@radix-ui/react-icons'
import { Badge, Button, Code, DataList, Flex, IconButton, Tooltip } from '@radix-ui/themes'
import { calculateTimeLeft, convertTimestampToIverifyDate } from '../../utils/dates'
import { Member } from '../../types/MemberTypes'
import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import UnblockMemberModal from './UnblockMemberModal'
import { useAppDispatch } from '../../reducers/store'
import { getConditionalAccessConfig, getScimStatus, selectSettings } from '../../reducers/settings'
import RemoveMemberModal from './RemoveMemberModal'
import { useSelector } from 'react-redux'
import { IconHourglassHigh } from '@tabler/icons-react'
import { getMembers, postRemoveMemberGracePeriod } from '../../reducers/members'

interface IAlertPopoutProps {
  popoutData: Member
  setShowPopout: (show: boolean) => void
}

export const MemberPopoutContent = (props: IAlertPopoutProps) => {
  const { popoutData, setShowPopout } = props
  const { scim } = useSelector(selectSettings)
  const di = useAppDispatch()
  const [showUnblockModal, setShowUnblockModal] = useState(false)
  const [showConditionalAccess, setShowConditionalAccess] = useState<boolean>(false)
  const [showRemoveMemberModal, setShowRemoveMemberModal] = useState<boolean>(false)

  useEffect(() => {
    fetchConditionalAccess()
    di(getScimStatus())
  }, [])

  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value)
    toast.success('Copied to clipboard', {
      duration: 6000,
      position: 'bottom-right',
    })
  }

  const generateCaView = (
    isBlocked?: boolean,
    failedToBlock?: boolean,
    gracePeriod?: string,
    blockedByPolicy?: string
  ) => {
    if (isBlocked && memberInGracePeriod(gracePeriod) && !blockedByPolicy?.includes('AlertSeverity')) {
      return <p className="text-[#D6A35C]">In Grace Period</p>
    } else if (failedToBlock) {
      return <p className="text-[#d93d42]">Failed to Blocked</p>
    } else if (isBlocked) {
      return <p className="text-[#d93d42]">Blocked</p>
    } else {
      return <p className="text-black">Active</p>
    }
  }

  const memberInGracePeriod = (gracePeriod?: string) => {
    const now = new Date()
    const gracePeriodDate = new Date(gracePeriod ?? '')
    return now < gracePeriodDate
  }

  const fetchConditionalAccess = async () => {
    const conditionalAccess = await di(getConditionalAccessConfig())
    setShowConditionalAccess(conditionalAccess?.active ?? false)
  }

  const removeGracePeriod = async (memberId: string, memberEmail: string) => {
    const gracePeriodRemoved = await di(postRemoveMemberGracePeriod(memberId))
    if (!gracePeriodRemoved) {
      toast.error(`An error occurred while blocking ${memberEmail}`, { duration: 6000 })
    } else {
      toast.success(
        `The grace period for ${memberEmail} has been removed. They may become blocked if not compliant at the time of their next scan`,
        { duration: 6000 }
      )
      di(getMembers())
      setShowPopout(false)
    }
  }

  return (
    <>
      {/* Name */}
      <div className="w-full pb-16">
        <div className="w-full h-20 border-b flex px-8">
          <div className="w-3/4 h-20 flex flex-col justify-center">
            <div className="text-black text-lg font-semibold leading-normal whitespace-normal max-two-lines">
              {popoutData.name}
            </div>
          </div>
          <div className="w-1/4 h-20 flex justify-end items-center float-right">
            <div
              className="w-6 h-6 cursor-pointer"
              onClick={() => {
                setShowPopout(false)
              }}
            >
              <Cross2Icon className="w-6 h-6" />
            </div>
          </div>
        </div>
        {/* Email */}
        <div className="w-full h-12 border-b flex px-8">
          <div className="w-1/2 h-12 flex flex-col justify-center">
            <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Email</div>
          </div>
          <div className="w-1/2 h-12 flex justify-end items-center float-right">
            <Tooltip
              content={
                <>
                  <p>{popoutData.email}</p>
                </>
              }
            >
              <div className="w-full text-black text-sm font-semibold font-['Open Sans'] leading-normal overflow-hidden overflow-ellipsis whitespace-nowrap">
                {popoutData.email}
              </div>
            </Tooltip>
          </div>
        </div>
        {/* Added */}
        <div className="w-full h-12 border-b flex px-8">
          <div className="w-1/2 h-12 flex flex-col justify-center">
            <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Added</div>
          </div>
          <div className="w-1/2 h-12 flex justify-end items-center float-right">
            <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
              {convertTimestampToIverifyDate(popoutData.createdAt, true)}
            </div>
          </div>
        </div>
        {/* No. Devices */}
        <div className="w-full h-12 border-b flex px-8">
          <div className="w-1/2 h-12 flex flex-col justify-center">
            <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">No. of Devices</div>
          </div>
          <div className="w-1/2 h-12 flex justify-end items-center float-right">
            <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
              {popoutData.devices.length}
            </div>
          </div>
        </div>
        {/* Conditional Access */}
        {showConditionalAccess && (
          <div className="w-full h-12 border-b flex px-8">
            <div className="w-1/2 h-12 flex flex-col justify-center">
              <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Conditional Access</div>
            </div>
            <div className="w-1/2 h-12 flex justify-end items-center float-right">
              <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
                {generateCaView(
                  popoutData.isBlocked,
                  popoutData.failedToBlock,
                  popoutData.gracePeriod,
                  popoutData.blockedByPolicy
                )}
              </div>
            </div>
          </div>
        )}
        {/* Grace Period */}
        {memberInGracePeriod(popoutData.gracePeriod) && (
          <div className="w-full h-12 border-b flex px-8">
            <div className="w-1/2 h-12 flex flex-col justify-center">
              <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">In Grace Period</div>
            </div>
            <div className="w-1/2 h-12 flex justify-end items-center float-right">
              <div className="text-[#D6A35C] text-sm font-semibold font-['Open Sans'] leading-normal">Yes</div>
            </div>
          </div>
        )}
        {popoutData.devices &&
          popoutData.devices.map((device, index) => (
            <div className="w-full border-b px-8 pb-4" key={device.code + index}>
              <div className="flex">
                <div className="w-1/2 h-12 flex flex-col justify-center">
                  <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Device Details</div>
                </div>
              </div>
              <DataList.Root>
                {device.deviceName && (
                  <DataList.Item>
                    <DataList.Label minWidth="88px">Device Name</DataList.Label>
                    <DataList.Value>{device.deviceName}</DataList.Value>
                  </DataList.Item>
                )}
                <DataList.Item>
                  <DataList.Label minWidth="88px">Device</DataList.Label>
                  <DataList.Value>{device.model}</DataList.Value>
                </DataList.Item>
                <DataList.Item align="center">
                  <DataList.Label minWidth="88px">Status</DataList.Label>
                  <DataList.Value>
                    <Badge
                      color={device.status === 'ACTIVE' ? (device.identifier !== null ? 'jade' : 'iris') : 'crimson'}
                      variant="soft"
                      radius="full"
                    >
                      {device.status === 'ACTIVE'
                        ? device.identifier !== null
                          ? 'Active'
                          : 'Not Activated'
                        : 'Removed'}
                    </Badge>
                  </DataList.Value>
                </DataList.Item>
                <DataList.Item>
                  <DataList.Label minWidth="88px">Code</DataList.Label>
                  <DataList.Value>
                    <Flex align="center" gap="2">
                      <Code variant="ghost">{device.code}</Code>
                      <IconButton
                        size="1"
                        aria-label="Copy value"
                        color="gray"
                        variant="ghost"
                        onClick={() => copyToClipboard(device.code)}
                      >
                        <CopyIcon />
                      </IconButton>
                    </Flex>
                  </DataList.Value>
                </DataList.Item>
                <DataList.Item>
                  <DataList.Label minWidth="88px">Platform</DataList.Label>
                  <DataList.Value>{device.platform}</DataList.Value>
                </DataList.Item>
                <DataList.Item>
                  <DataList.Label minWidth="88px">Added</DataList.Label>
                  <DataList.Value>{convertTimestampToIverifyDate(device.createdAt, true)}</DataList.Value>
                </DataList.Item>
              </DataList.Root>
            </div>
          ))}
        <div className="h-20 flex items-center mt-4">
          {scim?.enabled ? (
            <Tooltip
              content={"Please remove this member from your organization's SSO provider as you have SCIM enabled"}
            >
              <Button className="w-[90%] h-10 bg-[#d93d42] rounded-lg mx-auto opacity-50 cursor-not-allowed" disabled>
                Remove Member
              </Button>
            </Tooltip>
          ) : (
            <Button
              className="w-[90%] h-10 bg-[#d93d42] rounded-lg mx-auto cursor-pointer"
              onClick={() => {
                setShowRemoveMemberModal(true)
              }}
            >
              Remove Member
            </Button>
          )}
        </div>
        {popoutData.isBlocked && (
          <div
            onClick={() => setShowUnblockModal(true)}
            className="h-16 w-[399px] fixed bottom-0 right-0 bg-white border-t-[1px] flex items-center"
          >
            <Button className="h-[38px] bg-PurpleBlue-10 ml-8 cursor-pointer ml-auto mr-8">Unblock User</Button>
          </div>
        )}
        <div className="h-16 w-[399px] fixed bottom-0 right-0 bg-white border-t-[1px] flex items-center">
          {popoutData.isBlocked && !memberInGracePeriod(popoutData.gracePeriod) && (
            <Button
              className="h-[38px] bg-PurpleBlue-10 ml-8 cursor-pointer ml-auto mr-2"
              onClick={() => setShowUnblockModal(true)}
            >
              Unblock User
            </Button>
          )}
          {memberInGracePeriod(popoutData.gracePeriod) && (
            <div className="block w-full">
              <p className="text-sm text-gray-500 ml-2 mt-0.5">Grace Period Remaining</p>
              <div className="flex justify-between">
                <div className="flex ml-2">
                  <IconHourglassHigh size={20} className="mt-0.5" />
                  <p className="leading-normal ml-2">{calculateTimeLeft(popoutData.gracePeriod)}</p>
                </div>
                <Button
                  className="h-[38px] bg-PurpleBlue-10 ml-8 cursor-pointer ml-auto mr-2 -mt-3"
                  onClick={() => removeGracePeriod(popoutData.id, popoutData.email)}
                >
                  Remove
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      {showUnblockModal && (
        <UnblockMemberModal
          setShowModal={setShowUnblockModal}
          memberName={popoutData.name}
          memberId={popoutData.id}
          memberBlockedByPolicy={popoutData.blockedByPolicy!}
          setShowPopout={setShowPopout}
        />
      )}
      {showRemoveMemberModal && (
        <RemoveMemberModal
          setShowModal={setShowRemoveMemberModal}
          memberName={popoutData.name}
          memberId={popoutData.id}
          setShowPopout={setShowPopout}
        />
      )}
    </>
  )
}
