import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../reducers/store'
import { fetchScans, selectActivity } from '../../reducers/activity'
import { useSelector } from 'react-redux'
import DataGrid from '../data-grid/DataGrid'
import { CrossCircledIcon, MagnifyingGlassIcon } from '@radix-ui/react-icons'
import checkIcon from '../../assets/check_circle.svg'
import Popout from '../popout/Popout'
import { activityColumns } from './ActivityColumns'
import { SelectOption } from '../../types/MemberTypes'
import { DEFAULT_PAGE, MAXIMUM_PAGE_SIZE } from '../../strings/api-consts'
import { IconButton, SegmentedControl, TextField } from '@radix-ui/themes'
import { generateAndDownloadReport } from '../../reducers/alerts'
import { selectUser } from '../../reducers/users'
import { ScanMethod } from '../../types/AlertTypes'
import PageSkeleton from '../skeleton/PageSkeleton'
import { Pagination } from '../pagination/Pagination'
import { Activity, ActivityPopoutRequest } from '../../types/ActivityTypes'

function ActivityWrapper() {
  const di = useAppDispatch()
  const { scans, totalPages, currentPage } = useSelector(selectActivity)
  const { session } = useSelector(selectUser)

  const [showPopout, setShowPopout] = useState<boolean>(false)
  const [dataLoading, setDataLoading] = useState<boolean>(true)
  const [popoutData, setPopoutData] = useState<ActivityPopoutRequest | undefined>(undefined)
  const [contentScreen, setContentScreen] = useState<string>('EDR')
  const [nameFilter, setNameFilter] = useState<string>('')
  const [methodToShow, setMethodToShow] = useState<string>('sysdiagnose')

  useEffect(() => {
    setDataLoading(true)
    di(fetchScans(DEFAULT_PAGE, MAXIMUM_PAGE_SIZE, [], nameFilter, methodToShow))
  }, [methodToShow])

  useEffect(() => {
    if (scans || (scans && (scans as Activity[]).length === 0)) {
      setDataLoading(false)
    } else if (!scans) {
      setDataLoading(true)
    }
  }, [scans])

  const handlePageClick = (data: SelectOption) => {
    setDataLoading(true)
    di(fetchScans(data.selected + 1, MAXIMUM_PAGE_SIZE, [], nameFilter, methodToShow))
  }

  const getScanDetails = async (scanId: string, scanType: string) => {
    const scanDetails = {
      scanId: scanId,
      scanType: scanType,
    }

    if (scanType === ScanMethod.IVY_APP) {
      setContentScreen('EDR')
    } else if (scanType === ScanMethod.APP_SYSDIAGNOSE) {
      setContentScreen('DEEP')
    } else if (scanType === ScanMethod.IVERIFY_ELITE) {
      setContentScreen('DEEP')
    } else {
      setContentScreen('DEEP')
    }
    setPopoutData(scanDetails)
    setShowPopout(true)
  }

  const searchName = () => {
    setDataLoading(true)
    di(fetchScans(DEFAULT_PAGE, MAXIMUM_PAGE_SIZE, [], nameFilter, methodToShow))
  }

  const clearNameFilter = () => {
    setDataLoading(true)
    di(fetchScans(DEFAULT_PAGE, MAXIMUM_PAGE_SIZE, [], '', methodToShow))
  }

  const downloadAlertReport = async (scanId: string, isThreatHunter: boolean) => {
    await di(generateAndDownloadReport(scanId, isThreatHunter))
  }

  const noActivityText = () => {
    switch (methodToShow) {
      case 'edr':
        return 'monitoring'
      case 'threat_hunter':
        return 'threat hunter'
      case 'sysdiagnose':
        return 'forensic scans'
      default:
        ''
    }
  }

  return (
    <>
      <div className="absolute top-18 left-52 rounded-tl-lg border-t border-l bg-neutral-3 w-[calc(100%_-_208px)] h-[calc(100%_-_74px)] p-9">
        {!dataLoading && (
          <div className="flex justify-between">
            <div className="mb-6 flex w-full">
              <SegmentedControl.Root
                value={methodToShow}
                defaultValue="sysdiagnose"
                className="h-[38px] mr-4 bg-[#00003B0D] text-gray-600"
                onValueChange={(newValue: string) => setMethodToShow(newValue)}
              >
                <SegmentedControl.Item value="sysdiagnose">Forensic Scans</SegmentedControl.Item>
                <SegmentedControl.Item value="edr">Monitoring</SegmentedControl.Item>
                {session?.portalPreferences.threatHunterAccess && (
                  <SegmentedControl.Item value="threat_hunter">Threat Hunter</SegmentedControl.Item>
                )}
                <SegmentedControl.Item value="elite">Elite Scans</SegmentedControl.Item>
              </SegmentedControl.Root>
              <TextField.Root
                value={nameFilter}
                placeholder="Search..."
                className="w-[200px] h-[38px] mr-4"
                onChange={(e) => {
                  setNameFilter(e.target.value)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    searchName()
                  }
                }}
              >
                <TextField.Slot></TextField.Slot>
                {nameFilter !== '' && (
                  <TextField.Slot>
                    <IconButton
                      size="1"
                      variant="ghost"
                      onClick={() => {
                        setNameFilter('')
                        clearNameFilter()
                      }}
                    >
                      <CrossCircledIcon height="14" width="14" />
                    </IconButton>
                  </TextField.Slot>
                )}
                <TextField.Slot>
                  <IconButton size="1" variant="ghost" onClick={() => searchName()}>
                    <MagnifyingGlassIcon height="14" width="14" />
                  </IconButton>
                </TextField.Slot>
              </TextField.Root>
            </div>
          </div>
        )}

        {(!scans || dataLoading) && (
          <div className="flex justify-center items-center min-h-screen">
            <PageSkeleton />
          </div>
        )}
        {scans && scans.length > 0 && (
          <>
            <DataGrid
              columns={activityColumns(getScanDetails, downloadAlertReport, methodToShow)}
              data={scans}
              loading={dataLoading}
            />
            <div className="mt-2">
              <Pagination totalPages={totalPages} forcePage={currentPage} handlePageClick={handlePageClick} />
            </div>
          </>
        )}
        {scans && scans.length === 0 && (
          <div className="flex items-center justify-center h-screen">
            <div className="text-center -mt-32">
              <div className="flex items-center justify-center">
                <img src={checkIcon} alt="No open alerts found" className="h-12 w-12" />
              </div>
              <p className="font-bold">No Activity</p>
              <p className="text-sm">You have no {noActivityText()} activity to view</p>
            </div>
          </div>
        )}
      </div>
      {showPopout && <Popout setShowPopout={setShowPopout} contentScreen={contentScreen} popoutData={popoutData} />}
    </>
  )
}

export default ActivityWrapper
